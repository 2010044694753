import React from 'react';
import Document from '../../../../components/documents/Documents';

function ProviderDocument(props :any) {
    return (
        <div>
           
            <Document {...props} />
        </div>
    )
}

export default ProviderDocument
