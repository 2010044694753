import { useEffect } from 'react';
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom'
import MasterHOC from '../../../hoc/MasterHOC';
import DocumentManagement from './document-management/DocumentManagement';
import AnnouncementManagment from './announcement-management/AnnouncementManagement';
function DocumentAnnouncement() {
  let { path, url } = useRouteMatch();
  return (
    <div className="right-sec-wrapper">

      <div className="row m-0" >
        <div className="col-2 p-0">
          <div className="left-nav-bx " style={{ height: 800 }} >
            <nav className="navbar " >
              <ul className="navbar-nav left-m">
                <li className="nav-item">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/document`}>
                    Document <span></span>
                    </NavLink>
                  </h6>
                </li>
                <li className="nav-item">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/announcement`}>
                    Announcement <span></span>
                    </NavLink>
                  </h6>
                </li>
              </ul>
            </nav>
          </div>

        </div>


        <div className="col-10 p-5 right-sec">
          <Switch>
            <Route path={`${path}/document`} component={DocumentManagement} />
            <Route path={`${path}/announcement`} component={AnnouncementManagment} />
          </Switch>
        </div>

      </div>
    </div>
  )
}

export default MasterHOC(DocumentAnnouncement)
