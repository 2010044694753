import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Grid } from '@material-ui/core';
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
import { DocumentService } from '../../../../service/DocumentService';
import './Announcement.scss';
import Utili from '../../../../utili/Utili';
function AnnouncementManagement() {
    const {
        register,
        control,
        handleSubmit,
        reset: resetViewAnnouncement,
        getValues,
        formState: { errors },
    } = useForm();

    const submit = (data: any) => {

        DocumentService.UpdateAnnouncement(data.announcement, data.heading).then((res) => {
            Utili.notifyUpdated();
         
        }).catch((err) => {
            console.log(err);
        });
        console.log(data)
    }

    useEffect(() => {

        DocumentService.ViewAnnouncement().then((res) => {
            resetViewAnnouncement({ announcement: res.data });
           
        }).catch((err) => {
            console.log(err);
        });

    }, [])
    return (
        <div className='ann-page'>
   
            <SpinnerLoader isGeneral={false} tasksList={['updateAnnouncement','ViewAnnouncement']} inInternal={true} />
            <h4>Announcement</h4>

            {/* <label>Heading :</label>
            <input

                {...register("heading",
                    {
                       

                        maxLength: {
                            value: 25,
                            message: 'This input exceed maxLength.',
                        },
                    })}
                className="form-control textarea-c"
                placeholder="Heading "
            />
            <div className="popup-error">
                <ErrorMessage
                    errors={errors}
                    name="heading"
                    render={({ message }) => <p>{message}</p>}
                />
            </div> */}
            
            <Grid className="form-group announcement-cont" item xs={12} sm={8}>


                <label>Announcement :</label>
                
                <textarea
                    rows={4}
                    {...register("announcement",
                        {
                            required: "The Announcement is required",

                            maxLength: {
                                value: 500,
                                message: 'This input exceed maxLength.',
                            },
                        })}
                    className="form-control textarea-c"
                    placeholder="Announcement "
                />
                


            </Grid>
            <div className="popup-error">
                    <ErrorMessage
                        errors={errors}
                        name="announcement"
                        render={({ message }) => <p>{message}</p>}
                    />
                </div>
            <Grid className="form-group submitbtn-outer" item xs={12} sm={8}>
                <Button className="button popup-button" onClick={handleSubmit((data) => submit(data))}>
                    Submit
                </Button>
            </Grid>

        </div>
    )
}

export default AnnouncementManagement
