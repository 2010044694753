import React, { useEffect, useRef, useState } from 'react'
import UserService from '../../../../service/UserService';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import { Modal } from 'react-bootstrap';
import state from './state.json';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
import Utili from '../../../../utili/Utili';
import NumberFormat from 'react-number-format';
const List: any = [];
function ProviderUsers() {
    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 20,
            filterable: false,
        },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,


        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,

        },

        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            width: 160,
            operatorValue: 'contains',

        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: 100,
            renderCell: (params: any) =>
                params.row.user.isActive ? 'Active' : 'Inactive',
        },
        {
            field: '',
            headerName: 'Action',

            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params: any) => (
                <EditIcon
                    data-toggle="modal"
                    data-target="#editModal"
                    className="edit"
                    onClick={() => editAdmin(providerlist.find((e: any) => e.id == params.row.id))}
                    fontSize="small"
                />
            ),

        },
    ];
    const [providerlist, setProviderlist] = useState(List);
    const {
        register: UserEdit,
        formState: { errors: errorsEdit },
        handleSubmit: submitUser,
        control,
        reset: resetEditFrom,

    } = useForm();

    const editAdmin = (user: any) => {
       
        setShowEdit(true);
        resetEditFrom(
            {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                officeName: user.officeName,
                npi: user.npi,
                taxID: user.taxID,
                addressLine1: user.addressLine1,
                addressLine2: user.addressLine2,
                city: user.city,
                state: user.state,
                zipCode: user.zipCode,
                officeContactNumber: user.officeContactNumber,
                isActive : user.user.isActive,
            }
        );

    };
    // code

    useEffect(() => {
        UserService.GetProviderUserList().then((res) => {
            setProviderlist(res.data)

        }).catch((err) => {
            console.log(err);
        });
    }, [])

    const [showEdit, setShowEdit] = useState(false);


    const onSubmitEditAdmin = (user: any) => {

        UserService.updateProviderbyAdmin(user).then((res) => {
            setShowEdit(false);
            UserService.GetProviderUserList().then((res) => {
                setProviderlist(res.data);

                Utili.notifyUpdated();

            })
                .catch((err: any) => {
                    console.log(err);
                });
        }).catch((err: any) => {
            Utili.notifyError();
            console.log(err);
        });

    };



    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['getprovideruserlist', 'updateProviderbyAdmin']} inInternal={true} />
            {
                showEdit ?
                    <div>

                        <h3>Edit Provider </h3>
                        <Paper onSubmit={submitUser(data => onSubmitEditAdmin(data))} component="form" className="signup-form edit-profile"  >



                            <Grid item xs={7} container spacing={2}  >
                                <input  {...UserEdit("id",)} hidden={true} />
                                <Grid item xs={12} sm={6}>
                                    <TextField

                                        className="signup-input"
                                        {...UserEdit("firstName",
                                            {
                                                required: "The FirstName is required",
                                                pattern: {
                                                    value: /^[aA-zZ\s]+$/i,
                                                    message: 'This input is alphabet only.',
                                                },
                                                maxLength: {
                                                    value: 25,
                                                    message: 'This input exceed maxLength.',
                                                },

                                            })}

                                        label="First Name"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="firstName"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("lastName",
                                            {
                                                required: "The LastName is required",
                                                pattern: {
                                                    value: /^[aA-zZ\s]+$/i,
                                                    message: 'This input is alphabet only.',
                                                },
                                                maxLength: {
                                                    value: 25,
                                                    message: 'This input exceed maxLength.',
                                                },

                                            })}

                                        label="Last Name"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="lastName"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("email",
                                            {
                                                required: "The EmailAddress is required.",
                                                pattern: {
                                                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                    message: 'Email Pattern does not match',
                                                },


                                            })}

                                        label="Email address"
                                        disabled
                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="email"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("officeName",
                                            {
                                                required: "The OfficeName is required."


                                            })}

                                        label="Office Name"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="officeName"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        multiline

                                        rows={3}
                                        className="signup-input txta"
                                        {...UserEdit("taxID",
                                            {
                                                required: "The TaxID is required.",
                                                pattern: {
                                                    value: /^.{0,8}[0-9](?:\r?\n.{0,9}){0,8}$/i,
                                                    message: 'Enter valid TaxId.',
                                                },

                                            })}

                                        label="Tax ID"
                                        type="number"
                                       
                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="taxID"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("npi")}

                                        label="NPI"
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("addressLine1",
                                            {
                                                required: "The Address1 is required."

                                            })}

                                        label="Physical Address 1"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="addressLine1"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("addressLine2")}

                                        label="Physical Address 2"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="addressLine2"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className="signup-input"
                                        {...UserEdit("city",
                                            {
                                                required: "The City is required."

                                            })}

                                        label="City"

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="city"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="states-wrapper">

                                        <Controller
                                            name={"state"}
                                            control={control}
                                            rules={{ required: "The State is required." }}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        State :  {//@ts-ignore
                                                            // profile.state
                                                        }</InputLabel>
                                                    <Select

                                                        labelId="demo-simple-select-label"
                                                        value={value}
                                                        // onChange={onChange}
                                                        {...UserEdit("state", {
                                                            required: "select one option"
                                                        })}
                                                    >
                                                        {state.map((option: any) => {
                                                            return (
                                                                <MenuItem value={option.Code}>
                                                                    {option.State}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errorsEdit}
                                            name="state"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField

                                        className="signup-input"

                                        {...UserEdit("zipCode",
                                            {
                                                required: "The ZipCode is required.",
                                                maxLength: {
                                                    value: 10,
                                                    message: 'This input exceed maxLength.',
                                                },

                                            })}

                                        label="Zip Code"
                                        type='number'

                                    />
                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="zipCode"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <Controller
                                        control={control}
                                        rules={{ required: "The OfficePhoneNumber is required." }}
                                        name="officeContactNumber"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumberFormat
                                                className="signup-input"
                                                label="Office Phone number"
                                                format="(###) ###-####"
                                                name={name}
                                                customInput={TextField}
                                                value={value}
                                                onChange={onChange}

                                            />
                                        )}
                                    />

                                    <ErrorMessage
                                        errors={errorsEdit}
                                        name="officeContactNumber"
                                        render={({ message }) => <p>{message}</p>}
                                    />

                                </Grid>
                                <Grid  item xs={12} sm={6}>
                                <label>User Status: </label>
                                <label className="pt-2 status-wrap">
                                    <input {...UserEdit("isActive",)} name="isActive" type="checkbox" />{" "}
                                     Enabled
                                </label>
                            </Grid>
                                <Grid item xs={12} sm={12} >
                                    <div className="p-ft space-btns">
                                        <Button size="small" className="button form-button" type="submit" variant="contained" >Submit</Button>
                                        <Button size="small" className="button form-button cancel-btn"  variant="contained" onClick={()=> setShowEdit(false) } > Cancel</Button>
                                    </div>
                                </Grid>
                            </Grid>




                        </Paper>
                    </div>
                    :
                    <div>
                        <div className="grid-header">
                            <h3>Provider User Search</h3>

                        </div>
                        <div className="grid-wrapper admin-grid" style={{ height: 600 }}>

                            <DataGrid
                                rows={providerlist}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                columns={columns}
                                filterMode='client'
                                disableDensitySelector={true}
                                disableColumnSelector={true}

                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                disableColumnMenu
                                headerHeight={50}
                                rowHeight={38}
                                disableSelectionOnClick
                            />
                        </div>

                    </div>
            }


        </div>
    )
}

export default ProviderUsers
