import React from 'react';
import EditableProfile from '../../../components/edit-profile/EditableProfile';
import SpinnerLoader from '../../../components/spinner-loader/SpinnerLoader.component';

function EditProfile(props : any) {
    return (
        <div>
             <SpinnerLoader isGeneral={false} tasksList={['updateprovider']} inInternal={true} />
            <EditableProfile {...props} />
        </div>
    )
}

export default EditProfile
