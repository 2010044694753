import React, { useEffect, useState } from 'react'
import { DocumentService } from '../../service/DocumentService'
import './Announce.scss';
function Announcement() {
    const [announcement, setannouncement] = useState<any>()

    useEffect(() => {

        DocumentService.ViewAnnouncement().then((res) => {
            setannouncement(res.data)
            console.log(true)
        }).catch((err) => {
            console.log(err);
        });

    }, [])
    return (
        <div  className="puclic-document-container">
             <h4>Announcement</h4>
            <div className="announcement-bx"><p>{announcement}</p></div>
            
        </div>
    )
}

export default Announcement
