import React from 'react'

function SharedDocument() {
    return (
        <div>
            <h3>Shared Document</h3>
        </div>
    )
}

export default SharedDocument
