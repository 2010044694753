import React from 'react'
import MemberSearch from '../../../../components/member-search/MemberSearch';
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
function ProviderMemberSearch(props: any) {
    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['memberSearch', 'validatemember','provideroffices']} inInternal={true} />
            <MemberSearch {...props} />
        </div>
    )
}

export default ProviderMemberSearch
