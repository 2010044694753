import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import { confirmAlert } from 'react-confirm-alert';
import { ErrorMessage } from '@hookform/error-message';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { DocumentService } from '../../../../service/DocumentService';
import { Button } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
import Utili from '../../../../utili/Utili';
import './DocumentMang.scss';
import { padding } from '@mui/system';
const Documents: any = [];
function DocumentManagement() {

    const columnsClaims = [
        {
            field: 'documentId',
            headerName: '#',
            width: 70,
            filterable: false,
        },

        {
            field: 'fileName',
            headerName: 'Document Name',
            flex: 0.5,
            width: 250,
            operatorValue: 'contains',

        },
        {
            field: 'isPublic',
            headerName: 'Type',
            width: 100,
            renderCell: (params: any) =>
                params.row.isPublic ? 'Public' : 'Private',
        },
        {
            field: 'taxID',
            flex: 1,
            headerName: 'TaxID',
            width: 250,

        },

        {
            field: 'isPublish',
            headerName: 'Status',
            width: 100,
            renderCell: (params: any) =>
                params.row.isPublish ? 'Published' : 'Not Published',
        },

        {
            field: '',
            headerName: 'Action',

            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params: any) => (
                <>
                    <div className="ico-i edit-icon">
                        <EditIcon
                            data-toggle="modal"
                            data-target="#editModal"
                            className="edit"
                            onClick={() => editDocument(documents.find((e: any) => e.documentId == params.row.documentId))}
                            fontSize="small"
                        />
                    </div>

                    <div className="ico-i del-icon">
                        <CloseIcon
                            data-toggle="modal"
                            data-target="#editModal"
                            className="edit"
                            onClick={() => DeleteDocument(documents.find((e: any) => e.documentId == params.row.documentId))}
                            fontSize="small"
                        />
                    </div>
                </>
            ),

        },
    ];

    const [documents, setDocuments] = useState(Documents);

    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    const handleCloseEdit = () => {
        setShowEdit(false);
        setShowTaxidEdit(false);
    }
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowEdit = () => setShowEdit(true);
    const handleShowAdmin = () => setShowAdd(true);


    const {
        register: documentRejister,

        handleSubmit,
        reset: resetUpload,
        getValues: getUpload,
        formState: { errors: uplaodErr },
    } = useForm();

    const {
        register: editDoc,
        control,
        handleSubmit: editSubmit,
        reset: resetEdit,
        getValues: getEdit,
        formState: { errors: editErr },
    } = useForm();

    const DeleteDocument = (data: any) => {

        confirmAlert({
            title: "Confirm",
            message: "Are you sure to Delete the Document? You will not be able to change again!",

            buttons: [
                {
                    className: "button",
                    label: " Confirm",
                    onClick: () => {

                        DocumentService.DeleteDocument(data.documentId).then((res) => {
                            console.log(true);
                            Utili.notifyUpdated();
                            DocumentService.GetAllDocumentList().then((res) => {
                                setDocuments(res.data);
                                console.log(true);
                            })

                        }).catch((err) => {
                            console.log(err);
                        });

                    },
                },
                {
                    className: 'cancel-btn button',
                    label: "Cancel",
                    onClick: () => { },
                },
            ],
        });



    }
    const [isPublic, setisPublic] = useState(false);
    const editDocument = (data: any) => {
        if (data.isPublic) {


            setisPublic(true)
        } else {
            setShowTaxidEdit(true);
            setisPublic(false)
        }


        handleShowEdit();
        resetEdit(
            {

                documentId: data.documentId,
                taxID: data.taxID,
                fileName: data.fileName,
                isPublish: data.isPublish,
                categoryName: data.categoryName,
                categoryID: data.categoryID,
                isPublic: data.isPublic,

            }
        );

    }

    const UpdateDocument = (data: any) => {

        const formData = new FormData();
        formData.append('attachment', data.attachment[0]);
        formData.append('fileName', data.fileName);
        formData.append('documentId', data.documentId);
        formData.append('categoryId', data.categoryId);
        formData.append('type', data.type);
        formData.append('isPublish', data.isPublish);
        formData.append('taxID', data.taxID);

        // formData.entries();
        for (var key of
            //@ts-ignore
            formData.entries()) {
            console.log(key[0] + ', ' + key[1])
        }

        DocumentService.UpdateDocument(formData).then((res) => {
            Utili.notifyUpdated();
            handleCloseEdit();
            DocumentService.GetAllDocumentList().then((res) => {
                setDocuments(res.data);
                console.log(true)
            }).catch((err) => {
                console.log(err);
            });


        }).catch((err) => {
            console.log(err);
        })

    }

    const Attachment = (data: any) => {
        console.log(data.target.files[0]);

    }

    const taxIdHandler = (data: any) => {
        if (data === "Private") {
            setShowTaxid(true);
        }
        if (data === "Public") {
            setShowTaxid(false);
        }

    }

    const taxIdHandlerEdit = (data: any) => {
        if (data === "Private") {
            setShowTaxidEdit(true);
        }
        if (data === "Public") {
            setShowTaxidEdit(false);
        }

    }
    const [showTaxidedit, setShowTaxidEdit] = useState(false);
    const [showTaxid, setShowTaxid] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isPDF, setisPDF] = useState(false);

    const UplaodDocument = (data: any) => {
        if (data.type === 'Private') {
            if(data.categoryId === '1'){
                data.categoryId = '2';
            }


            if (data.taxID === '' || data.taxID === undefined) {

                setShowMessage(true);
            } else {
                setShowMessage(false);

                const formData = new FormData();
                formData.append('attachment', data.attachment[0]);
                formData.append('fileName', data.fileName);
                formData.append('categoryId', data.categoryId);
                formData.append('type', data.type);
                formData.append('isPublish', data.isPublish);
                formData.append('taxID', data.taxID);

                // formData.entries();
                for (var key of
                    //@ts-ignore
                    formData.entries()) {
                    console.log(key[0] + ', ' + key[1])
                }

                DocumentService.CreateDocument(formData).then((res) => {
                    if (res.data.message === 'Only PDF file are allowed.') {
                        setisPDF(true);

                    } else if (res.data.message === "'Couldn't upload the document.") {
                        setisPDF(true);

                    } else {
                        setisPDF(false);
                        Utili.notifyAdded();

                        setisfileName(false);

                        resetUpload(
                            {
                                attachment: null,
                                documentId: "",
                                taxID: "",
                                fileName: "",
                                isPublish: false,
                                categoryName: "",
                                categoryID: "",
                                isPublic: false,

                            }
                        );

                        DocumentService.GetAllDocumentList().then((res) => {
                            setDocuments(res.data);
                            console.log(true)
                        }).catch((err) => {
                            console.log(err);
                        });

                    }
                    DocumentService.GetAllDocumentList().then((res) => {
                        setDocuments(res.data);
                        console.log(true)
                    }).catch((err) => {
                        console.log(err);
                    });


                }).catch((err) => {
                    console.log(err);
                })

            }

        } else if (data.type === 'Public') {
            if(data.categoryId === '2' || data.categoryId === '3' ){
                data.categoryId = '1';
            }

            const formData = new FormData();
            formData.append('attachment', data.attachment[0]);
            formData.append('fileName', data.fileName);
            formData.append('categoryId', data.categoryId);
            formData.append('type', data.type);
            formData.append('isPublish', data.isPublish);


            // formData.entries();
            for (var key of
                //@ts-ignore
                formData.entries()) {
                console.log(key[0] + ', ' + key[1])
            }

            DocumentService.CreateDocument(formData).then((res) => {
                if (res.data.message === 'Only PDF file are allowed.') {
                    setisPDF(true);

                } else if (res.data.message === "Couldn't upload the document.") {
                    setisPDF(true);

                } else {
                    setisPDF(false);
                    Utili.notifyAdded();

                    setisfileName(false);

                    resetUpload(
                        {
                            attachment: null,
                            documentId: "",
                            taxID: "",
                            fileName: "",
                            isPublish: false,
                            categoryName: "",
                            categoryID: "",
                            isPublic: false,

                        }
                    );

                    DocumentService.GetAllDocumentList().then((res) => {
                        setDocuments(res.data);

                    }).catch((err) => {
                        console.log(err);
                    });

                }

            }).catch((err) => {
                console.log(err);
            })

        }
    }
    const [isfileName, setisfileName] = useState(false);
    const [fileName, setfileName] = useState('');
    const removeFileName = (e: any) => {
        setisfileName(true);
        var file = document.getElementById('files');
        setfileName(e.target.value.replace(/C:\\fakepath\\/, ''))
        // var filename = e.target.value.replace(/C:\\fakepath\\/, '');
        // setfileName(filename);
    }

    useEffect(() => {

        DocumentService.GetAllDocumentList().then((res) => {
            setDocuments(res.data);

        }).catch((err) => {
            console.log(err);
        });

    }, [])

    return (
        <div className='document-mang-page'>
            <SpinnerLoader isGeneral={false} tasksList={['CreateDocument', 'GetAllDocumentList']} inInternal={true} />
            <h4> Upload Document</h4>

            <form onSubmit={handleSubmit(UplaodDocument)} >
                <div className="form-group">
                    <label>Select Document : </label>



                    <input type='file' accept="application/pdf" title=" " className='uploadinput'
                        {...documentRejister("attachment",
                            {
                                required: "The Document is required",
                            })}
                        onChange={(e) => removeFileName(e)}
                    />
                    {
                        isfileName ?
                            <span>{fileName}</span>
                            :
                            ''
                    }


                </div>
                <div className="popup-error">
                    {
                        isPDF ?
                            <p>Only PDF file are allowed</p>
                            :
                            ''
                    }
                    <ErrorMessage
                        errors={uplaodErr}
                        name="attachment"
                        render={({ message }) => <p>{message}</p>}
                    />
                </div>
                <div className="form-group">
                    <label>Document Title: </label>
                    <input
                        type='text'
                        {...documentRejister("fileName", {
                            required: "The Document Title is required",
                        }

                        )}
                    />

                </div>
                <div className="popup-error">
                    <ErrorMessage
                        errors={uplaodErr}
                        name="fileName"
                        render={({ message }) => <p>{message}</p>}
                    />
                </div>
                <div className="form-group">
                    <label>Type : </label>
                    <select id="type"

                        {...documentRejister("type")}
                        onChange={(data) => taxIdHandler(data.target.value)}
                    >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Category : </label>
                  
                    <select id="Category"
                        {...documentRejister("categoryId", {
                            required: "The Category is required",
                        })}
                    >
                        {
                            showTaxid ?

                                <>
                                <option value="" disabled selected >Select Category...</option>
                                    
                                   
                                    <option value="2">Training</option>
                                    <option value="3">Benefits</option>
                                </>
                                :
                                <>
                                 <option value="" disabled selected >Select Category...</option>
                                 <option value="1">General</option>
                                </>
                               
                        }



                    </select>

                </div>
                <div className="popup-error">
                    <ErrorMessage
                        errors={uplaodErr}
                        name="categoryId"
                        render={({ message }) => <p>{message}</p>}
                    />
                </div>

                {
                    showTaxid ?
                        <div className="form-group">
                            <label>TaxID : </label>
                            <textarea
                                rows={3}
                                {...documentRejister("taxID",
                                    {

                                        pattern: {
                                            value: /^.{0,8}[0-9](?:\r?\n.{0,9}){0,8}$/i,
                                            message: 'Enter valid TaxId.',
                                        },


                                    })}
                            />
                        </div>
                        :
                        ''

                }
                {
                    showMessage ?
                        <p style={{ color: "#f00", marginLeft: 150 }}>Enter TaxID</p>
                        :
                        ''
                }


                <div className="form-group">
                    <label>Publish : </label>
                    <input type='checkbox'
                        {...documentRejister("isPublish")}
                    />
                </div>
                <div className="form-group">
                    <label> </label>
                    <Button className='button upload-btn' type='submit'  >Upload</Button>
                </div>

            </form>

            <div>
                <h4>All Documents</h4>
                <div style={{ height: 380 }}>
                    <DataGrid
                        rows={documents}

                        columns={columnsClaims}
                        getRowId={(row) => row.documentId}
                        filterMode='client'
                        disableDensitySelector={true}
                        disableColumnSelector={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableColumnMenu
                        headerHeight={50}
                        rowHeight={38}
                        disableSelectionOnClick
                    />
                </div>

            </div>

            <Modal show={showEdit} onHide={handleCloseEdit} >
                <Modal.Header className="popup-head">
                    <Button className="closepopup" onClick={handleCloseEdit}><span>x</span></Button>
                    <Modal.Title>Edit Document</Modal.Title>
                </Modal.Header>
                <form className='doc-modal' onSubmit={editSubmit(UpdateDocument)} >
                    <div className="form-group">
                        <label>Select Document: </label>
                        <input type='file' accept="application/pdf"
                            {...editDoc("attachment",
                            )}
                        />
                        
                    </div>
                    <div className="popup-error">
                            <ErrorMessage
                                errors={editErr}
                                name="attachment"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>
                    <div className="form-group">
                        <label>Document Title: </label>
                        <input
                            type='text'
                            {...editDoc("fileName", {
                                required: "The Document Title is required",
                            }

                            )}
                        />
                        
                    </div>
                    <div className="popup-error">
                            <ErrorMessage
                                errors={editErr}
                                name="fileName"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>
                    <div className="form-group">
                        <label>Type : </label>
                        <select id="type"

                            {...editDoc("type")}
                            onChange={(data) => taxIdHandlerEdit(data.target.value)}
                        >
                            {
                                isPublic ?
                                    <option value="Public">Public</option>
                                    :
                                    <option value="Private">Private</option>
                            }


                        </select>
                    </div>
                    <div className="form-group">
                        <label>Category : </label>
                        <select id="Category"
                            {...editDoc("categoryId")}
                        >
                            {
                                showTaxidedit ?

                                    <>
                                        <option value="2">Training</option>
                                        <option value="3">Benefits</option>
                                    </>
                                    :
                                    <option value="1">General</option>
                            }



                        </select>
                    </div>

                    {
                        showTaxidedit ?
                            <>
                                <div className="form-group">
                                    <label>TaxID : </label>
                                    <input
                                        type='text'
                                        {...editDoc("taxID",
                                            {

                                                pattern: {
                                                    value: /^.{0,8}[0-9](?:\r?\n.{0,9}){0,8}$/i,
                                                    message: 'Enter valid TaxId.',
                                                },


                                            })}
                                    />
                                </div>
                                <div className="popup-error">
                                    <ErrorMessage
                                        errors={editErr}
                                        name="taxID"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>
                            </>

                            :
                            ''

                    }
                    {
                        showMessage ?
                            <p style={{ color: "#f00", marginLeft: 150 }}>Enter TaxID</p>
                            :
                            ''
                    }


                    <div className="form-group">
                        <label>Publish : </label>
                        <input type='checkbox'
                            {...editDoc("isPublish")}
                        />
                    </div>
                    <div className="form-group">
                        <label> </label>
                        <Button className='button upload-btn' type='submit'  >Update</Button>
                    </div>

                </form>

            </Modal>

        </div>
    )
}

export default DocumentManagement
