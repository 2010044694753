import React, { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Grid, TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AuthService } from '../../../service/AuthService';
import UserService from '../../../service/UserService';
import Utili from '../../../utili/Utili';
import SpinnerLoader from '../../../components/spinner-loader/SpinnerLoader.component';
import { Link } from 'react-router-dom';
import './ConfirmEmail.scss';

function Confirmation(props: any) {

  const {
    register: newPassword,
    formState: { errors: errorsAddAdmin },
    handleSubmit: NewSubmitPassword,
    reset: resetUsernameFrom,
    watch,

  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const [showchangepassword, setShowchangepassword] = useState(false);
  const [isExpired, setisExpired] = useState(false);
  const [isValid, setisValid] = useState(false);


  var parameter = props.location.search;

  const onSubmitNewPassword = (password: any) => {
    AuthService.changeForgotPassword(parameter, password).then((res) => {
      if (res.data.statusMessage === "Password changed.") {
        Utili.notifyUpdated();
        props.history.push("/signin");
      } else if (res.data.statusMessage === "InValid request.") {

      }
      console.log(res);

    }).catch((err) => {
      console.log(err);
    })


  

  };

  useEffect(() => {
    AuthService.confirmForgotPassword(parameter).then((res) => {
      if (res.data.statusMessage === "Valid Request.") {

        setShowchangepassword(true);
      } else if (res.data.statusMessage === "Request is Expired.") {
        setisExpired(true);
        setShowchangepassword(false);
      } else if (res.data.statusMessage === "Invalid request.") {
        setisValid(true);
        setisExpired(false);
        setShowchangepassword(false);
      }

    }).catch((err) => {
      console.log(err);
    });

  }, []);

  return (

    <div style={{ paddingTop: 10 }}>
      <div className="container confimemail">
        <SpinnerLoader isGeneral={false} tasksList={['conficonfirmforgotpasswordrmemail', 'createadmin',]} inInternal={true} />


        {
          showchangepassword ?
            <>
              <div className="logo-wrapper"> <Link className="logo" to="/admin"></Link></div>
              <div className="confirm-wrapper">
                <h3>Change Password</h3>
                <form onSubmit={NewSubmitPassword(onSubmitNewPassword)} >
                  <div className="form-container change-passwrod">
                    <Grid className="form-group" item xs={12} sm={12}>

                      <TextField {...newPassword("password",
                        {
                          required: "The New Password field is required",
                          pattern: {
                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@_?#$%^&*])[a-zA-Z0-9!@_?#$%^&*]{8,25}$/,
                            message: 'Password does not meet the criteria',
                          },


                        })}
                        className="form-input"
                        type="password"
                        label="New Password"
                      />

                      <div className="info-div">
                        <p><ul>
                          <li>Password must contain at least
                            8 characters</li>
                          <li>Including
                            Upper/Lowercase</li>
                          <li>Mixture of
                            letters/numbers </li>
                          <li>One special
                            character</li>
                        </ul> </p>
                      </div>

                    </Grid>
                    <div className="popup-error-changepassword">

                      <ErrorMessage
                        errors={errorsAddAdmin}
                        name="password"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </div>
                    <Grid className="form-group" item xs={12} sm={12}>

                      <TextField

                        {...newPassword("confirmpassword",
                          {
                            validate: value =>
                              value === password.current || "Password does not match"
                          })}
                        className="form-input"
                        type="password"
                        label="Confirm Password"
                      />

                    </Grid>
                    <div className="popup-error-changepassword">
                      {errorsAddAdmin.confirmpassword && <p>{errorsAddAdmin.confirmpassword.message}</p>}
                    </div>

                    <Grid className="form-group" item xs={12} sm={12}>
                      <div className="form-btn-cont">
                        <Button className="button change-passwrod-btn" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Grid>

                  </div>

                </form>
              </div>
            </>
            :
            isExpired ?
              <p>Your  Request Expired</p>
              :
              isValid ?
                <p>Invalid Request</p>
                :
                ''
        }




      </div>
    </div>



  )
}

export default Confirmation
