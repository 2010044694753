import { Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Announcement from '../../components/announcement/Announcement';
import { ErrorMessage } from '@hookform/error-message';
import state from './state.json';
import SpinnerLoader from '../../components/spinner-loader/SpinnerLoader.component';
import UserService from '../../service/UserService';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputGroup, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { AuthService } from '../../service/AuthService';
import Utili from '../../utili/Utili';



function SignUp(props: any) {
    // { resolver: yupResolver<yup.AnyObjectSchema>(SignUpSchema), }
    const { register: UserSignUp, control, watch, formState: { errors, isValid, isDirty }, handleSubmit: submitUser } = useForm({ mode: "onChange" });



    // const { fields, append } = useFieldArray({
    //     control,
    //     name: "taxIDs"
    // });
    // const watchFieldArray = watch("taxIDs");
    // const controlledFields = fields.map((field: any, index: any) => {

    //     return {
    //         ...field,
    //         ...watchFieldArray[index]
    //     };
    // });

    const onSubmit = (provider: any) => {
      

        UserService.ProviderSignUP(provider).then((res) => {
            if (res.data.statusCode === "RegenerateOTP") {
                setAllreadysignup(true);
            } else if (res.data.statusCode === "Success") {
                setshowconfirmation(true);
            } else if (res.data.statusCode === "RequestUnderApproval" || res.data.statusCode === "RequestAlreadyApproved") {
                Utili.requestSameEmail();
            }
       
        })
            .catch((err) => {
                console.log(err);
            });
        setconfirmEmail(provider.email);
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [button_bool, setButton_bool] = useState(true);
    const [checked, setChecked] = useState(false);
    const Disable_EnableButton = (event: any) => {
       
        setChecked(event.target.checked);
        if (event.target.checked === true) {
            setButton_bool(false);
        } else {
            setButton_bool(true);
        }

    };
    const { register: registercode, handleSubmit: submitcode } = useForm();
    const [confirmEmail, setconfirmEmail] = useState<any>()
    const [showconfirmation, setshowconfirmation] = useState(false);
    const [showallreadysignup, setAllreadysignup] = useState(false);
    const [showallreadyused, setAllreadyused] = useState(false);
    const [showinvalidotp, setInvalidotp] = useState(false);

    const submitOtp = (otp: any) => {
        AuthService.confirmEmail(otp).then((res) => {
            if (res.data.statusCode === "Success") {
                Utili.requestSubmited();
                props.history.push("/");
            } else if (res.data.statusCode === "Used") {
                setAllreadyused(true);
            }
            else if (res.data.statusMessage === "OTP request is not valid.") {
                setInvalidotp(true);
            }
          
          
        }).catch((err: any) => {
            console.log(err);
        });
     
    };
    const resendCode = (email: any) => {
        UserService.RegenerateSignupRequestOTP(email).then((res) => {
            
        }).catch((err: any) => {
            console.log(err);
        });
        setshowconfirmation(true)
       
    };
    useEffect(() => {
        const userType = localStorage.getItem("userType");
        
        if (userType === "admin") {
            props.history.push("/requestmanagement/requestlist");
        } else if (userType === "provider") {
            props.history.push("/providermemberservice/membersearch");
        }
    
      }, [])

    return (
        <div className="page">
            <SpinnerLoader isGeneral={false} tasksList={['providersignup']} inInternal={true} />
            <Header{...props} />
            <div className="form-outer">
                {
                    showconfirmation ?
                        <div>

                            <Paper onSubmit={submitcode(submitOtp)} component="form" className="signin-form init-form" >

                                <h2>Verifying email</h2>
                                <div className="signin-wrapper">
                                    <p style={{ marginBottom: '2px' }}>we have sent you code on email {confirmEmail}</p>
                                    <Grid item xs={12} container   >

                                        <Grid item xs={12} sm={12}>
                                            <input hidden={true} defaultValue={confirmEmail}
                                                {...registercode("email",)} />
                                            <TextField {...registercode("otp",
                                                {
                                                    required: "The EmailAddress is required."


                                                })} type="password" className="signin-input" label="Enter Code" />
                                            <ErrorMessage
                                                errors={errors}
                                                name="otp"
                                                render={({ message }) => <p>{message}</p>}
                                            />
                                            {
                                                showallreadyused? <p className="error-msg">Allready Used OTP</p>:''
                                            }
                                            {
                                                showinvalidotp?<p className="error-msg">Invalid OTP</p>:''
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className="clearfix"></div>
                                    <div className="form-actions">
                                        <Button className="resend-btn" onClick={() => resendCode(confirmEmail)} variant="contained" size="small"  >Resend Code</Button>
                                    </div>
                                    <div className="form-actions verify-ac">
                                        <Button className="button form-button cancel-btn" onClick={() => setshowconfirmation(false)} variant="contained" size="small"  >Cancel</Button>
                                        <Button className="button form-button" type="submit" variant="contained" size="small"  >Confirm</Button>
                                    </div>
                                </div>
                            </Paper>

                        </div>
                        :
                        <div>
                            {
                                showallreadysignup ?
                                    <Paper className="signin-form init-form" >
                                        <div className="signin-wrapper">
                                            <h2>Under pending verification</h2>
                                            <p>Your account is under pending verification.</p>
                                            {/* <input hidden={true} defaultValue={confirmEmail}
                                                {...registercode("email",)} /> */}
                                            <div className="form-actions">
                                                <Button className="resend-btn" onClick={() => resendCode(confirmEmail)} variant="contained" size="small"  >Resend code</Button>
                                            </div>
                                            <div className="form-actions verify-ac">
                                                <Button className="button form-button float-left" onClick={() => setAllreadysignup(false)} variant="contained" size="small" >Back to Home</Button>
                                            </div>
                                        </div>

                                    </Paper>
                                    :
                                    <Paper onSubmit={submitUser(data => onSubmit(data))} component="form" className="signup-form init-form"  >

                                        <h2>PROVIDER SIGN UP</h2>

                                        <Grid item xs={7} container spacing={2}  >
                                            <Grid item xs={12} sm={6}>
                                                <TextField

                                                    className="signup-input"
                                                    {...UserSignUp("firstName",
                                                        {
                                                            required: "The FirstName is required",
                                                            pattern: {
                                                                value: /^[aA-zZ\s]+$/i,
                                                                message: 'This input is alphabet only.',
                                                            },
                                                            maxLength: {
                                                                value: 25,
                                                                message: 'This input exceed maxLength.',
                                                            },

                                                        })}

                                                    label="First Name"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="firstName"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>

                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("lastName",
                                                        {
                                                            required: "The LastName is required",
                                                            pattern: {
                                                                value: /^[aA-zZ\s]+$/i,
                                                                message: 'This input is alphabet only.',
                                                            },
                                                            maxLength: {
                                                                value: 25,
                                                                message: 'This input exceed maxLength.',
                                                            },

                                                        })}

                                                    label="Last Name"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="lastName"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("email",
                                                        {
                                                            required: "The EmailAddress is required.",
                                                            pattern: {
                                                                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                                message: 'Please enter a valid EmailAddress',
                                                            },


                                                        })}

                                                    label="Email address"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("officeName",
                                                        {
                                                            required: "The OfficeName is required."


                                                        })}

                                                    label="Office Name"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="officeName"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>

                                                <TextField
                                                    multiline

                                                    rows={3}
                                                    className="signup-input"
                                                    {...UserSignUp("taxID",
                                                        {
                                                            required: "The TaxID is required.",
                                                            pattern: {
                                                                value: /^.{0,8}[0-9](?:\r?\n.{0,9}){0,8}$/i,
                                                                message: 'Enter valid TaxId.',
                                                            },

                                                        })}

                                                    label="Tax ID"
                                                    type="number"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="taxID"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("npi")}

                                                    label="NPI"
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("addressLine1",
                                                        {
                                                            required: "The Address1 is required."

                                                        })}

                                                    label="Physical Address 1"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="addressLine1"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("addressLine2")}

                                                    label="Physical Address 2"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="addressLine2"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className="signup-input"
                                                    {...UserSignUp("city",
                                                        {
                                                            required: "The City is required."

                                                        })}

                                                    label="City"

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="states-wrapper">

                                                    <Controller
                                                        name={"state"}
                                                        control={control}
                                                        rules={{ required: "The State is required." }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                                <Select

                                                                    labelId="demo-simple-select-label"
                                                                    value={value}
                                                                    // onChange={onChange}
                                                                    {...UserSignUp("state", {
                                                                        required: "select one option"
                                                                    })}
                                                                >
                                                                    {state.map((option: any) => {
                                                                        return (
                                                                            <MenuItem value={option.Code}>
                                                                                {option.State}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="state"
                                                        render={({ message }) => <p>{message}</p>}
                                                    />
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField

                                                    className="signup-input"

                                                    {...UserSignUp("zipCode",
                                                        {
                                                            required: "The ZipCode is required.",
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'This input exceed maxLength.',
                                                            },

                                                        })}

                                                    label="Zip Code"
                                                    type='number'

                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="zipCode"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>

                                                {/* <TextField
    
                                                className="signup-input"
                                                {...UserSignUp("officeContactNumber",
                                                    {
                                                        required: "This is required"
                                                    })}
                                                name="officeContactNumber"
                                                label="Office Phone number"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom
                                                }}
    
                                            /> */}

                                                <Controller
                                                    control={control}
                                                    rules={{ required: "The OfficePhoneNumber is required." }}
                                                    name="officeContactNumber"
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <NumberFormat
                                                            className="signup-input"
                                                            label="Office Phone number"
                                                            format="(###) ###-####"
                                                            name={name}
                                                            customInput={TextField}
                                                            value={value}
                                                            onChange={onChange}

                                                        />
                                                    )}
                                                />

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="officeContactNumber"
                                                    render={({ message }) => <p>{message}</p>}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={12} >
                                                <div className="p-ft">
                                                    <InputGroup className="check-wrap">
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={Disable_EnableButton}
                                                        />
                                                        <FormControl aria-label="Text input with checkbox" />
                                                        <div className="check-text">I agree to the FloridaDentalBenefits <a onClick={handleShow} href="javascript:void(0);">Terms and Conditions</a></div>

                                                    </InputGroup>
                                                    <Button size="small" disabled={button_bool || !isValid || !isDirty} className="button form-button" type="submit" variant="contained" >

                                                        Submit

                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>




                                    </Paper>

                            }

                        </div>

                }
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Terms and Conditions</Modal.Title>
                    <Button className="closepopup" onClick={handleClose}>x</Button>
                </Modal.Header>
                <Modal.Body>As the authorized user, I hereby submit this attestation to
                    comply with applicable provisions of the Administrative Simplification
                    provisions of the Health Insurance Portability and Accountability Act
                    of 1996 ("HIPAA") as amended by the Health Information Technology for
                    Economic and Clinical Health Act ("HITECH") (enacted as part of the
                    American Recovery and Reinvestment Act of 2009) and the Affordable Care
                    Act ("ACA") (Public Law Nos. 111-148 and 111-152, enacted in March 2010)
                    and the standards, operating rules, and related regulations and guidance
                    promulgated thereunder (referred to collectively, hereinafter, as "the HIPAA
                    requirements"), as may be amended from time to time.</Modal.Body>

            </Modal>
            <div>
                <Announcement {...props} />
            </div>
            <Footer {...props} />
        </div>

    )
}

export default SignUp
