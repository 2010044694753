    import { Button, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { Grid } from '@mui/material';
import { InputGroup, Modal } from 'react-bootstrap';
import React ,{ useEffect, useState }from 'react';
import Item from '../backnavigation/Back';

function Footer() {
    const PrivacyPolicy = "This provider portal is the property of Florida Dental Benefits, Inc.  It is for authorized users  only. Users (authorized or unauthorized) have no  explicit expectation of  privacy.  Any or all  users of this  system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed to authorized personnel of Florida Dental Benefits,     Inc. By using this system, the user consents to such interception, monitoring,     recording, copying, auditing, inspection, and disclosure at the discretion of Florida Dental Benefits, Inc. personnel. Unauthorized or improper use of this portal may result in administrative disciplinary action and civil and criminal penalties. By continuing to use this system you indicate your awareness of and consent to this privacy policy.";
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div >
            <footer className="bg-color fixed-bottom">
                <div className="web-container">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <a onClick={handleShow} className="policy-btn" href="javascript:void(0);">Privacy Policy </a>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="ft-right">
                                <p> Toll free number</p>
                                <a className="policy-btn" > 877-674-7901</a>
                            </div>
                        </Grid>

                    </Grid>

                    <div className="footer-copyright py-2 text-light ">© FloridaDentalBenefits</div>
                </div>
            </footer>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Privacy Policy</Modal.Title>
                    <Button className="closepopup" onClick={handleClose}>x</Button>
                </Modal.Header>
                <Modal.Body>{PrivacyPolicy}</Modal.Body>

            </Modal>
        </div>

    );
}

export default Footer;