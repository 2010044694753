import React, { useEffect } from 'react'
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom'
import MasterHOC from '../../../hoc/MasterHOC';
import AdminUsers from "./admin-users/AdminUsers";
import ProviderUsers from './provider-user/ProviderUsers';
import SharedDocument from './shared-document/SharedDocument';
function UserManagement(props: any) {

  useEffect(() => {
    props.history.push("/usermanagement/adminusers");
  }, [])
  let { path, url } = useRouteMatch();
  return (
    <div className="right-sec-wrapper">

      <div className="row m-0" >
        <div className="col-2 p-0">
          <div className="left-nav-bx" style={{ height: 800 }} >
            <nav className="navbar " >
              <ul className="navbar-nav left-m">
                <li className="nav-item">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/adminusers`}>
                      Admin Users <span></span>
                    </NavLink>
                  </h6>
                </li>
                <li className="nav-item">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/providerusers`}>
                      Provider Users <span></span>
                    </NavLink>
                  </h6>
                </li>

                {/* <li className="nav-item">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/shareddocument`}>
                      Shared Document <span></span>
                    </NavLink>
                  </h6>
                </li> */}
              </ul>
            </nav>
          </div>

        </div>


        <div className="col-10 p-5 right-sec">
          <Switch>
          <Route path={`${path}/providerusers`} component={ProviderUsers} />
            <Route path={`${path}/adminusers`} component={AdminUsers} />
            <Route path={`${path}/shareddocument`} component={SharedDocument} />
          </Switch>
        </div>

      </div>
    </div>
  )
}

export default MasterHOC(UserManagement)
