import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Announcement from '../../components/announcement/Announcement';
import { Paper } from "@material-ui/core";
import { useEffect } from "react";



const Home = (props: any) => {


  useEffect(() => {
    const userType = localStorage.getItem("userType");
    
    
    if (userType === "admin") {
      props.history.push("/requestmanagement/requestlist");
  } else if (userType === "provider") {
      props.history.push("/providermemberservice/membersearch");
  }


  }, [])

 

  return (
    <div className="page">
      <Header {...props} />
      <div >
        <div>
          <Paper className="paper-home-container" >

          </Paper>
        </div>
      <Announcement />
      </div>
      <Footer {...props} />
    </div>

  );
}

export default Home;
