import Header from '../components/footer/Footer';
import Footer from '../components/header/Header';
import Auth from '../components/auth/Auth';
import Sessiontimeout from '../components/session-timeout/Sessiontimeout';

const MasterHOC = (Component: any) => (props: any) => {


    Sessiontimeout(props);
     Auth(props);
    return (
        <div >
            <Footer {...props} />
            <Component {...props} />
            <Header {...props} />
        </div>

    )
};
export default MasterHOC;