import React from 'react'
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom'
import MasterHOC from '../../../hoc/MasterHOC';
import ProviderMemberSearch from './provider-member-search/ProviderMemberSearch';
function ProviderMemberService() {
    let { path, url } = useRouteMatch();
    return (
        <div className="right-sec-wrapper">
      
        <div className="row m-0" >
          <div className="col-2 p-0">
           <div className="left-nav-bx"  style={{height:800}} >
           <nav className="navbar " >
                <ul className="navbar-nav left-m">
                  <li className="nav-item">
                    <h6>
                      <NavLink activeClassName="active" className="nav-link " to={`${url}/membersearch`}>
                      Member Search <span></span>
                      </NavLink>
                    </h6>
                  </li>
  
               
               
                </ul>
              </nav>
           </div>
           
            </div>
  
  
          <div className="col-10 p-5 right-sec">
            <Switch>
           
              <Route path={`${path}/membersearch`} component={ProviderMemberSearch} />
              
            </Switch>
          </div>
  
        </div>
      </div>
    )
}

export default MasterHOC(ProviderMemberService)
