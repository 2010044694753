import { useEffect } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { AuthService } from '../../service/AuthService';

const Auth = (props: any) => {

    const history = useHistory();
    let { path } = useRouteMatch();
    useEffect(() => {
        const userType = localStorage.getItem("userType");
        let isAuth = AuthService.checkAuth();
        if (!isAuth) {
            history.push("/");
        } else {
            if (path === '/' && userType === "admin") {
                history.push("/admin");
            } else if (path === '/' && userType === "provider") {
                history.push("/provider");
            }
        }
    }, [])

};
export default Auth;