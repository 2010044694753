import http from "./http-common";

const memberSearch = (data: any) => {
  return http.post(`api/1/Member/SearchMember`, data, {
    headers: { 'task_name': 'memberSearch' }
  });
};

const validateMember = (data: any) => {
  return http.post("api/1/Member/ValidateMemberOffice", data, {
    headers: { 'task_name': 'validatemember' }
  });
}

const providerOffices = () => {
  return http.get("user/1/Users/MyOffices", {
    headers: { 'task_name': 'provideroffices' }
  });
}
/*
const printMemberDetails = (id : any, offiId : any) =>
{
  return http.post(`api/1/Member/PrintMemberEligibility?memberId=${id}&officeId=${offiId}`, {
    headers: {'task_name': 'printMemberEligibility'}});
}
*/


const notifyMemberValidation = (membercode: any, officecode: any, dateofService: any) => {
  return http.get(`api/1/Member/DownloadMemberValidation?membercode=${membercode}&officecode=${officecode}&dateofService=${dateofService}`, {
    responseType: 'blob',
    headers: {
      'task_name': 'DownloadMemberValidation',
      'Content-Type': 'application/pdf'
    }

  });
}

const memberCliamHistory = (id: any) => {
  return http.post(`api/1/Member/GetMemberClaimHistory?memberId=${id}`, {
    headers: { 'task_name': 'memberCliamHistory' }
  });
}



const getUploadClaimAccessToken = () => {
  return http.get("api/1/Member/GetUploadClaimAccessToken", {
    headers: { 'task_name': 'getUploadClaimAccessToken' }
  });
};

export default {
  memberCliamHistory,
  providerOffices,
  validateMember,
  memberSearch,
  notifyMemberValidation,
  getUploadClaimAccessToken
};