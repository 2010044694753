import React, { useEffect, useRef, useState } from 'react'
import UserService from '../../../../service/UserService';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Modal } from 'react-bootstrap';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
import Utili from '../../../../utili/Utili';
import NumberFormat from 'react-number-format';


const AdminList: any = [];

const initialState = {

    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isActive: true,
};
function AdminUsers(props: any) {
    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 20,
            filterable: false,
        },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,


        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,

        },

        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            width: 160,

        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: 100,
            renderCell: (params: any) =>
                params.row.isActive ? 'Active' : 'Inactive',
        },
        {
            field: '',
            headerName: 'Action',

            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params: any) => (
                <EditIcon
                    data-toggle="modal"
                    data-target="#editModal"
                    className="edit"
                    onClick={() => editAdmin(adminlist.find((e: any) => e.id == params.row.id))}
                    fontSize="small"
                />
            ),

        },
    ];
    const [adminlist, setAdminlist] = useState(AdminList);
    const {
        register: editUser,
        formState: { errors: errorsEditAdmin },
        handleSubmit: submitUser,
        control,
        reset: resetEditFrom,

    } = useForm();

    const {
        register: addUser,
        formState: { errors: errorsAddAdmin },
        handleSubmit: AddSubmitUser,
        reset: resetAdminFrom,
        watch,

    } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const editAdmin = (user: any) => {
        handleShowEdit();
        resetEditFrom(
            {

                firstName: user.firstName,
                id: user.id,
                lastName: user.lastName,
                email: user.email,
                isActive: user.isActive,


            }
        );

    };

    useEffect(() => {
        UserService.GetAdminUserList().then((res) => {
            setAdminlist(res.data)

        }).catch((err) => {
            console.log(err);
        });
    }, [])

    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowEdit = () => setShowEdit(true);
    const handleShowAdmin = () => setShowAdd(true);



    const AddAdmin = () => {
        handleShowAdmin();
        resetAdminFrom(initialState);
    }

    const onSubmitEditAdmin = (user: any) => {

        UserService.updateAdmin(user).then((res) => {
            handleCloseEdit();
            UserService.GetAdminUserList().then((res) => {
                setAdminlist(res.data);

                Utili.notifyUpdated();

            })
                .catch((err: any) => {
                    console.log(err);
                });
        }).catch((err: any) => {
            Utili.notifyError();
            console.log(err);
        });

    };

    const onSubmitAddAdmin = (user: any) => {

        UserService.createAdmin(user).then((res) => {
            console.log(res);
            handleCloseAdd();
            if (res.data.statusCode === 'Success')
                {
                    UserService.GetAdminUserList().then((res) => {
                        setAdminlist(res.data);
        
                        Utili.notifyAdded();
                        console.log(res.data);
                    })
                        .catch((err: any) => {
                            console.log(err);
                        });

                }
            else if (res.data.statusCode === 'DuplicateError')
            {
                Utili.notifyDuplicate();
                console.log(res.data);
            }
            else
            {
                Utili.notifyError();
                console.log(res.data);                
            }
        }).catch((err: any) => {
            Utili.notifyError();
            console.log(err);

        });

        console.log(user);

    };

    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['getadminuserlist', 'createadmin', 'updateadmin']} inInternal={true} />

            <div>
                <div className="grid-header">
                    <h3>Admin User Search</h3>
                    <Button className="button add-admin" onClick={AddAdmin}>Add Admin</Button>
                </div>
                <div className="grid-wrapper admin-grid" style={{ height: 600 }}>

                    <DataGrid
                        rows={adminlist}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        columns={columns}
                        filterMode='client'
                        disableDensitySelector={true}
                        disableColumnSelector={true}

                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        disableColumnMenu
                        headerHeight={50}
                        rowHeight={38}
                        disableSelectionOnClick
                    />
                </div>
                <Modal show={showEdit} onHide={handleCloseEdit} >
                    <Modal.Header className="popup-head">
                        <Button className="closepopup admin" onClick={handleCloseEdit}><span>x</span></Button>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>

                    <form onSubmit={submitUser(onSubmitEditAdmin)} >
                        <div className="form-container">
                            <Grid className="form-group" item xs={12} sm={7}>
                                <input  {...editUser("id",)} hidden={true} />
                                <label>First Name :</label>
                                <TextField {...editUser("firstName",
                                    {
                                        required: "The FirstName is required",
                                        pattern: {
                                            value: /^[aA-zZ\s]+$/i,
                                            message: 'This input is alphabet only.',
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: 'This input exceed maxLength.',
                                        },
                                    })}
                                    className="form-input"
                                />
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsEditAdmin}
                                    name="firstName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Last Name :</label>
                                <TextField {...editUser("lastName",
                                    {
                                        required: "The LastName is required",
                                        pattern: {
                                            value: /^[aA-zZ\s]+$/i,
                                            message: 'This input is alphabet only.',
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: 'This input exceed maxLength.',
                                        },
                                    })}
                                    className="form-input"
                                />
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsEditAdmin}
                                    name="lastName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Email :</label>
                                <TextField {...editUser("email",

                                    {
                                        required: "The EmailAddress is required.",
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                            message: 'Please enter a valid EmailAddress',
                                        },


                                    })}

                                    className="form-input"
                                    disabled
                                />
                            </Grid>

                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>User Status :</label>
                                <label className="">
                                    <input {...editUser("isActive",)} name="isActive" type="checkbox" />{" "}
                                    Enabled
                                </label>
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsEditAdmin}
                                    name="email"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label></label>
                                <Button className="button popup-button" variant="primary" type="submit">
                                    Update
                                </Button>
                            </Grid>


                        </div>
                    </form>
                </Modal>

                <Modal show={showAdd} onHide={handleCloseAdd} >
                    <Modal.Header className="popup-head">
                        <Button className="closepopup admin" onClick={handleCloseAdd}><span>x</span></Button>
                        <Modal.Title>Add User</Modal.Title>
                    </Modal.Header>

                    <form onSubmit={AddSubmitUser(onSubmitAddAdmin)} >
                        <div className="form-container">
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>First Name :</label>
                                <TextField {...addUser("firstName",
                                    {
                                        required: "The FirstName is required",
                                        pattern: {
                                            value: /^[aA-zZ\s]+$/i,
                                            message: 'This input is alphabet only.',
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: 'This input exceed maxLength.',
                                        },
                                    })}
                                    className="form-input"
                                />
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="firstName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Last Name :</label>
                                <TextField {...addUser("lastName",
                                    {
                                        required: "The LastName is required",
                                        pattern: {
                                            value: /^[aA-zZ\s]+$/i,
                                            message: 'This input is alphabet only.',
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: 'This input exceed maxLength.',
                                        },
                                    })}

                                    className="form-input"
                                />
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="lastName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Email :</label>
                                <TextField {...addUser("email",
                                    {
                                        required: "The EmailAddress is required.",
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                            message: 'Please enter a valid EmailAddress',
                                        },

                                    })}

                                    className="form-input"
                                />
                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="email"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Password :</label>
                                <TextField {...addUser("password",
                                    {
                                        required: "The password is required.",
                                        pattern: {
                                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@_?#$%^&*])[a-zA-Z0-9!@_?#$%^&*]{8,25}$/,
                                            message: 'Password does not meet the criteria',
                                        },


                                    })}
                                    className="form-input"
                                    type="password"
                                />

                                <div className="info-div">
                                    <ul className='p-bullets'>
                                        <li>Password must contain at least
                                            8 characters</li>
                                        <li>Including
                                            Upper/Lowercase</li>
                                        <li>Mixture of
                                            letters/numbers </li>
                                        <li>One special
                                            character</li>
                                    </ul>
                                </div>

                            </Grid>
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="password"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>Confirm Password :</label>
                                <TextField

                                    {...addUser("confirmpassword",
                                        {
                                            validate: value =>
                                                value === password.current || "Password does not match"
                                        })}
                                    className="form-input"
                                    type="password"
                                />

                            </Grid>
                            <div className="popup-error">
                                {errorsAddAdmin.confirmpassword && <p>{errorsAddAdmin.confirmpassword.message}</p>}
                            </div>
                            <Grid className="form-group" item xs={12} sm={7}>
                                <label>User Status :</label>
                                <label className="">
                                    <input {...addUser("isActive",)} name="isActive" type="checkbox" />{" "}
                                    Enabled
                                </label>
                            </Grid>

                            <Grid className="form-group" item xs={12} sm={7}>
                                <label></label>
                                <Button className="button popup-button" type="submit">
                                    Add User
                                </Button>
                            </Grid>

                        </div>

                    </form>
                </Modal>

            </div>
        </div>
    )
}

export default AdminUsers
