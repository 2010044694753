import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import UserService from '../../service/UserService';
import EditIcon from "@material-ui/icons/Edit";
import { Modal } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import SpinnerLoader from '../spinner-loader/SpinnerLoader.component';
import './Datagrid.scss';
import Utili from '../../utili/Utili';
const GridList: any = [];
const initialState = {

    comment: "",

};
function Datagrid(props: any) {
    const [list, setList] = useState(GridList);
    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 15,
            filterable: false,
        },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,


        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,

        },

        {
            field: 'email',
            headerName: 'Email',

            width: 200,
            operatorValue: 'contains',

        },
        {
            field: 'taxID',
            headerName: 'taxID',

            width: 100,
            operatorValue: 'contains',

        },
        {
            field: 'officeContactNumber',
            headerName: 'Contact Number',

            width: 120,
            operatorValue: 'contains',

        },
        {
            field: 'officeName',
            headerName: 'Office Name',
            flex: 1,
            width: 150,
            operatorValue: 'contains',

        },
        {
            field: 'requestStatus',
            headerName: 'Status',

            width: 75,
            operatorValue: 'contains',

        },

        {
            field: '',
            headerName: 'Action',

            width: 80,
            filterable: false,
            sortable: false,
            renderCell: (params: any) => (
                <Button
                    className="view-popup"
                    onClick={() => viewrequest(list.find((e: any) => e.id == params.row.id))}

                >View</Button>
            ),

        },
    ];

    const {
        register,
        control,
        handleSubmit,
        reset: resetcomment,
        getValues,
        formState: { errors },
    } = useForm();

    const submit = (data: any, status: any) => {
        // @ts-ignore
        data.requestId = requestuser.id;
        data.action = status;
        data.requestStatus = status;
        setShowviewrequest(false);
        confirmAlert({
            title: "Confirm",
            message:
                status == "approve"
                    ? "Are you sure to Approve the Provider request? You will not be able to change again!"
                    : "Are you sure to Reject the Provider request? You will not be able to change again!.",
            buttons: [
                {
                    label: " Confirm",
                    onClick: () => {
                       
                        UserService.ApproveRejectSignUpRequest(data).then((res) => {
                            console.log(true);
                            Utili.notifyUpdated();
                            UserService.GetRequestList(requestparameter).then((res) => {
                                setList(res.data)
                                console.log(true);
                            })
                            
                        }) .catch((err) => {
                            console.log(err);
                        });

                    },
                },
                {
                    label: "Cancel",
                    onClick: () => { },
                },
            ],
        });
    };

    const [requestuser, setRequestuser] = useState({});
    const [requestparameter, setRequestparameter] = useState<any>();
    const [showviewrequest, setShowviewrequest] = useState(false);
    const viewrequest = (user: any) => {

        resetcomment(initialState);
        setRequestuser(user);
        setShowviewrequest(true);
       
    }
    const getREquestList = () => {
        var userSignupRequestStatus = "All";
        UserService.GetRequestList(userSignupRequestStatus).then((res) => {
            setList(res.data)

        }) .catch((err) => {
            console.log(err);
        });
    }

    const {
        register: searchForm,
        setValue: setValuesSearchForm,
        handleSubmit: submitSearchForm,
        reset: resetSearchFrom,
        watch,
        control: contorlSearch,
        getValues: getValuesSearchForm,
    } = useForm();

    const getWd = (data: any) => {
        setRequestparameter(data.target.value);
       
        UserService.GetRequestList(data.target.value).then((res) => {
            setList(res.data)

        }) .catch((err) => {
            console.log(err);
        });

    };


    useEffect(() => {
        setValuesSearchForm("userSignupRequestStatus", "All");
        getREquestList();
    }, [])
    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['getrequestlist', 'approverejectrequest']} inInternal={true} />

            <form autoComplete="off" className="form-inline" noValidate>
                <div className="mb-2">
                    <div className="p-2">
                        <div className="g-wrapper">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="All"
                                    id="all"
                                    {...searchForm("userSignupRequestStatus")}
                                    name="userSignupRequestStatus"

                                    onClick={(data) => getWd(data)}
                                />
                                <label className="custom-control-label" htmlFor="all">
                                    All
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="New"
                                    id="new"
                                    {...searchForm("userSignupRequestStatus")}
                                    name="userSignupRequestStatus"

                                    onClick={getWd}
                                />
                                <label className="custom-control-label" htmlFor="new">
                                    New
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Pending"
                                    id="pending"
                                    {...searchForm("userSignupRequestStatus")}
                                    name="userSignupRequestStatus"
                                    className="custom-control-input"
                                    onClick={getWd}
                                />
                                <label className="custom-control-label" htmlFor="pending">
                                    Pending
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Approved"
                                    id="approved"
                                    {...searchForm("userSignupRequestStatus")}
                                    name="userSignupRequestStatus"
                                    className="custom-control-input"
                                    onClick={getWd}
                                />
                                <label className="custom-control-label" htmlFor="approved">
                                    Approved
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Rejected"
                                    id="rejected"
                                    {...searchForm("userSignupRequestStatus")}
                                    name="userSignupRequestStatus"
                                    className="custom-control-input"
                                    onClick={getWd}
                                />
                                <label className="custom-control-label" htmlFor="rejected">
                                    Rejected
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className="grid-wrapper" style={{ height: 710 }}>

                <DataGrid
                    rows={list}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    columns={columns}
                    filterMode='client'
                    disableDensitySelector={true}
                    disableColumnSelector={true}

                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    disableColumnMenu
                    headerHeight={50}
                    rowHeight={38}
                    disableSelectionOnClick
                />
            </div>
            <Modal size="xl" className="request-list-modal" show={showviewrequest} onHide={() => setShowviewrequest(false)} >
                <Modal.Header className="popup-head">
                    <Button className="closepopup admin" onClick={() => setShowviewrequest(false)}>x</Button>
                    <Modal.Title>Provider Request</Modal.Title>
                </Modal.Header>


                <div className="form-container request-list-container">
                    <Grid className="form-group" item xs={12} sm={12}>
                        <Grid className="" item xs={12} sm={12}>
                            <Grid className="form-group" item xs={12} sm={10}>

                                <label>First Name :</label>
                                <div>{
                                    // @ts-ignore
                                    requestuser.firstName}</div>

                            </Grid>

                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Email :</label>

                                <div>{// @ts-ignore
                                    requestuser.email}</div>
                            </Grid>

                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>NPI :</label>
                                <div>{// @ts-ignore
                                    requestuser.npi}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Office Contact Number :</label>
                                <div>{  // @ts-ignore
                                    requestuser.officeContactNumber}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Zip Code :</label>
                                <div>{ // @ts-ignore
                                    requestuser.zipCode}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Address Line 1 :</label>
                                <div>{ // @ts-ignore
                                    requestuser.addressLine1}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Request Status :</label>
                                <div>{ // @ts-ignore
                                    requestuser.requestStatus}</div>
                            </Grid>


                        </Grid>
                        <Grid className="" item xs={12} sm={12}>
                            <Grid className="form-group" item xs={12} sm={10}>

                                <label>Last Name :</label>
                                <div>{ // @ts-ignore
                                    requestuser.lastName}</div>

                            </Grid>

                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>taxID :</label>
                                <div>{ // @ts-ignore
                                    requestuser.taxID}</div>
                            </Grid>

                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Office Name :</label>
                                <div>{ // @ts-ignore
                                    requestuser.officeName}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>City :</label>
                                <div>{ // @ts-ignore
                                    requestuser.city}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>State :</label>
                                <div>{ // @ts-ignore
                                    requestuser.state}</div>
                            </Grid>
                            <Grid className="form-group" item xs={12} sm={10}>
                                <label>Address Line2 :</label>
                                <div>{ // @ts-ignore
                                    requestuser.addressLine2}</div>
                            </Grid>

                        </Grid>






                    </Grid>
                    <Grid className="form-group" item xs={12} sm={12}>
                        {// @ts-ignore
                            requestuser.requestStatus === "New" ?
                                ''
                                :

                                // @ts-ignore
                                requestuser.requestStatus === "Rejected" ?
                                    ''
                                    :
                                    // @ts-ignore
                                    requestuser.requestStatus === "Approved" ?
                                        ''
                                        :
                                        <>
                                            <label>Comments :</label>
                                            <textarea

                                                {...register("comment")}
                                                className="form-control textarea-c"
                                                placeholder="Leave Comments for Provider"
                                            />
                                        </>
                        }

                    </Grid>




                    <Grid className="form-group form-buttons" item xs={12} sm={12}>
                        {// @ts-ignore
                            requestuser.requestStatus === "New" ?
                                <Button className="button popup-button cancel-btn" onClick={() => setShowviewrequest(false)}>
                                    Cancel
                                </Button>
                                :

                                // @ts-ignore
                                requestuser.requestStatus === "Rejected" ?
                                    <Button className="button popup-button cancel-btn" onClick={() => setShowviewrequest(false)}>
                                        Cancel
                                    </Button>
                                    :
                                    // @ts-ignore
                                    requestuser.requestStatus === "Approved" ?
                                        <Button className="button popup-button cancel-btn" onClick={() => setShowviewrequest(false)}>
                                            Cancel
                                        </Button>
                                        :
                                        <>
                                            <Button className="button popup-button" onClick={handleSubmit((data) => submit(data, 'approve'))}>
                                                Approve
                                            </Button>
                                            <Button className="button popup-button reject-btn" onClick={handleSubmit((data) =>
                                                submit(data, 'reject')
                                            )}>
                                                Reject
                                            </Button>
                                            <Button className="button popup-button cancel-btn" onClick={() => setShowviewrequest(false)}>
                                                Cancel
                                            </Button>
                                        </>
                        }




                    </Grid>


                </div>

            </Modal>
        </div>
    )
}

export default Datagrid
