import { ErrorMessage } from '@hookform/error-message';
import { Button, Grid, TextField } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import UserService from '../../service/UserService';
import SpinnerLoader from '../spinner-loader/SpinnerLoader.component';
import Utili from '../../utili/Utili';
import './ChangePasswrod.scss';

function ChangePassword(props: any) {
    let signinuser;
    const {
        register: newPassword,
        formState: { errors: errorsAddAdmin },
        handleSubmit: NewSubmitPassword,
        reset: resetUsernameFrom,
        watch,

    } = useForm();
    const newpassword = useRef({});
    newpassword.current = watch("newpassword", "");
    const [invalid, setinvalid] = useState(false);
    const onSubmitNewPassword = (password: any) => {
        UserService.changePassword(password).then((res) => {
            if (res.data.statusMessage === "Password changed") {
                Utili.notifyUpdated();
                const userType = localStorage.getItem("userType");
                if (userType === "provider") {
                    console.log("Go to Provider Dashboard");
                    props.history.push("/providermemberservice/membersearch");
                }

                setinvalid(false);
            } else if (res.data.statusMessage === "Invalid Request") {
                setinvalid(true);
            }


        }).catch((err) => {
            console.log(err);
        })


        

    };
    useEffect(() => {
        signinuser = localStorage.getItem("signinUserEmail")
        resetUsernameFrom({
            username: signinuser,
        })
    }, [])
    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['changepassword']} inInternal={true} />
            <h3>Change Password</h3>
            <form onSubmit={NewSubmitPassword(onSubmitNewPassword)} >
                <div className="form-container change-passwrod">

                    <input hidden={true}
                        {...newPassword("username",)} />


                    <Grid className="form-group" item xs={12} sm={12}>

                        <TextField {...newPassword("oldpassword",
                            {
                                required: "The Current Password field is required",
                                pattern: {
                                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@_?#$%^&*])[a-zA-Z0-9!@_?#$%^&*]{8,25}$/,
                                    message: 'Password does not meet the criteria',
                                },



                            })}

                            className="form-input"
                            label="Current Password"
                            type="password"
                        />
                    </Grid>
                    <div className="popup-error-changepassword">
                        {
                            invalid ?
                                <p>Invalid Password</p>
                                :
                                ''
                        }
                        <ErrorMessage
                            errors={errorsAddAdmin}
                            name="oldpassword"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </div>
                    <Grid className="form-group" item xs={12} sm={7}>

                        <TextField {...newPassword("newpassword",
                            {
                                required: "The New Password field is required",
                                pattern: {
                                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@_?#$%^&*])[a-zA-Z0-9!@_?#$%^&*]{8,25}$/,
                                    message: 'Password does not meet the criteria',
                                },


                            })}
                            className="form-input"
                            type="password"
                            label="New Password"
                        />

                        <div className="info-div">
                                <ul className='p-bullets'>
                                    <li>Password must contain at least
                                        8 characters</li>
                                    <li>Including
                                        Upper/Lowercase</li>
                                    <li>Mixture of
                                        letters/numbers </li>
                                    <li>One special
                                        character</li>
                                </ul> 
                        </div>

                    </Grid>
                    <div className="popup-error-changepassword">

                        <ErrorMessage
                            errors={errorsAddAdmin}
                            name="newpassword"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </div>
                    <Grid className="form-group" item xs={12} sm={7}>

                        <TextField

                            {...newPassword("confirmpassword",
                                {
                                    validate: value =>
                                        value === newpassword.current || "Password does not match"
                                })}
                            className="form-input"
                            type="password"
                            label="Confirm Password"
                        />

                    </Grid>
                    <div className="popup-error-changepassword">
                        {errorsAddAdmin.confirmpassword && <p>{errorsAddAdmin.confirmpassword.message}</p>}
                    </div>

                    <Grid className="form-group" item xs={12} sm={12}>
                        <div className="form-btn-cont">
                            <Button className="button change-passwrod-btn" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Grid>

                </div>

            </form>


        </div>
    )
}

export default ChangePassword
