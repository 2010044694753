import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { AuthService } from '../../service/AuthService';
import { Button } from '@material-ui/core';
import { Dropdown } from 'react-bootstrap';
import './Header.scss';
import EditProfile from '../../pages/provider-management/edit-profile/EditProfile';
import ProviderChangePassword from '../../pages/provider-management/provider-change-password/ProviderChangePassword';

function Header(props: any) {
    let { path, url } = useRouteMatch();
    const [userType, setUserType] = useState<any>();
    const [firstName, setfirstName] = useState<any>();
    const [lastName, setlastName] = useState<any>();

    const logout = () => {

        AuthService.logout(props);

    };
    useEffect(() => {
        setUserType(localStorage.getItem("userType"));
        setfirstName(localStorage.getItem("userFirstName"));
        setlastName(localStorage.getItem("userLastName"));
    }, )

    return (
        <div>
            <div className=" bg-white fixed-top " >
                <div className="main-header">
                    {
                        userType === "admin" ?

                            <Link className="logo" to="/admin"></Link>

                            :
                            userType === "provider" ?
                                <Link className="logo" to="/provider"></Link>
                                :
                                <Link className="logo" to=""></Link>

                    }

                    <nav className="navbar navbar-expand-lg  ">
                        <div className=" navbar-collapse float-right " >

                            {((props.history.location.pathname === "/signin") || (props.history.location.pathname === "/signup") || (props.history.location.pathname === "/")) === true ?
                                <ul className="navbar-nav top-nav">

                                    <li className="nav-item">
                                        <NavLink activeClassName="active-sublink" className="nav-link" to="/signin">SIGN-IN</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink activeClassName="active-sublink" className="nav-link" to="/signup">PROVIDER SIGN UP</NavLink>
                                    </li>

                                </ul>
                                :
                                ''
                            }



                            {userType === "admin" ?
                                <ul className="navbar-nav top-nav inner-top-nav">

                                    <li className="nav-item">
                                        <NavLink activeClassName="active-sublink" className="nav-link" to="/requestmanagement/requestlist">Request Management</NavLink>
                                    </li>


                                    <li className="nav-item">
                                        <NavLink activeClassName="active-sublink" className="nav-link" to="/usermanagement/adminusers">User Management</NavLink>
                                    </li>


                                    <li className="nav-item">
                                        <NavLink activeClassName="active-sublink" className="nav-link" to="/documentmanagement/document">Document & Announcement</NavLink>
                                    </li>


                                   


                                </ul>
                                :
                                userType === "provider" ?
                                    <ul className="navbar-nav top-nav inner-top-nav">

                                        <li className="nav-item">
                                            <NavLink activeClassName="active-sublink" className="nav-link" to="/providermemberservice/membersearch">Member Service</NavLink>
                                        </li>


                                        <li className="nav-item">
                                            <NavLink activeClassName="active-sublink" className="nav-link" to="/viewdocument/providerdocument">View Document </NavLink>
                                        </li>

                                    </ul>
                                    :
                                    ''
                            }

                        </div>
                        <div className="float-right logout">
                            {/* <Paper onSubmit={handleSubmit(onSubmit)} elevation={5} component="form" className={classes.search}>
                                <InputBase
                                    className={classes.input}
                                    {...register("search")}
                                    placeholder="Search "
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            
                            </Paper> */}
                            {userType === "admin" ?
                                <>
                                    <div className="top-info">
                                        <div className="user-name-wrap">
                                            <div className="avatar-div"></div>
                                            {/* <img src={require('./../../images/avatar-icon.png')} alt="BigCo Inc. logo"/>  */}
                                            <div className="user-name"> <span> {firstName} </span> <span> {lastName}</span> </div></div>
                                        {/* <Button size="large" onClick={logout} >Sign Out</Button> */}
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdown-btn">
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropdown-list">

                                                <Dropdown.Item href="/admin/changepassword">Change Password</Dropdown.Item>
                                                <Dropdown.Item href="" onClick={logout} >Sign Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                                :
                                userType === "provider" ?
                                    <>
                                        <div className="top-info">
                                            <div className="user-name-wrap">
                                                <div className="avatar-div"></div>
                                                {/* <img src={require('./../../images/avatar-icon.png')} alt="BigCo Inc. logo"/>  */}
                                                <div className="user-name">  <span> {firstName} </span> <span> {lastName}</span>  </div></div>
                                            {/* <Button size="large" onClick={logout} >Sign Out</Button> */}
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdown-btn">
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="dropdown-list">
                                                    <Dropdown.Item href="/provider/editprofile">
                                                        Edit Profile
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="/provider/changepassword"  >
                                                        Change Password
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="" onClick={logout} >Sign Out</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </>
                                    :
                                    ''
                            }
                        </div>
                    </nav>
                </div>
                <Switch>

                    {/* <Route path={`${path}/editprofile`} component={EditProfile} /> */}
                    <Route path={`${path}/changepassword`} />
                </Switch>
            </div>
        </div>
    );
}

export default Header;