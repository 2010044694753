import { Button, Checkbox, Grid, Paper, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { AuthService } from '../../service/AuthService';
import { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FormControl, InputGroup, Modal } from 'react-bootstrap';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Announcement from '../../components/announcement/Announcement';
import SpinnerLoader from './../../components/spinner-loader/SpinnerLoader.component';





function SignIn(props: any) {

    const { register: emailforgot,
        handleSubmit: submitEmail,
        reset: resetEmail,
        formState: { errors: emailerror },
    } = useForm();
    const { register: loginRegister, formState: { errors, isValid, isDirty }, handleSubmit: submitUser } = useForm({ mode: "onChange" });
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showsuccessemail, setShowsuccessemail] = useState(false);
    const SendEmail = (email: any) => {

        AuthService.forgotPassword(email).then((res: any) => {
           
            if (res.data.statusCode === "Success") {
                setShowsuccessemail(true);
                setShowforget(false);
            }  else if (res.data.statusMessage === "Not authorized") 
            {
                setuserNotAuthorized(true);
            }
            else if (res.data.statusMessage === "Invalid Email Address Provided.") 
            {
                setuserNotFound(true);
            }

        })
            .catch((err: any) => {
                console.log(err);
            });
      
        resetEmail({});

    };
    const [showforget, setShowforget] = useState(false);
    const forgetPassword = () => {
        setShowforget(true);
    }
    const [userNotFound, setuserNotFound] = useState(false);
    const [userNotAuthorized, setuserNotAuthorized] = useState(false);
    const submit = (signinuser: any) => {

        AuthService
            .login(signinuser, props)
            .then((res: any) => {
                if (res.data.token != null) {

                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("userType", res.data.userType.toLowerCase());
                    localStorage.setItem("userFirstName", res.data.userFirstName);
                    localStorage.setItem("userLastName", res.data.userLastName);
                    localStorage.setItem("signinUserEmail", signinuser.username);
                    setuserNotFound(false);
                    if (res.data.userType.toLowerCase() === "admin") {
                        console.log("Go to Admin Dashboard");
                        props.history.push("/requestmanagement/requestlist");
                    }

                    else if (res.data.userType.toLowerCase() === "provider") {

                        if (res.data.isUserRequireChangePassword === true) {
                            props.history.push("/provider/changepassword");
                        } else {
                            console.log("Go to Provider Dashboard");
                            props.history.push("/providermemberservice/membersearch");
                        }



                    }
                } else if (res.data.statusMessage === 'User Not found.') {
                    
                    setuserNotFound(true);
                    setuserNotAuthorized(false);
                } else if (res.data.statusMessage === 'Not Authorized') {
                    setuserNotFound(false);
                  
                    setuserNotAuthorized(true);
                } else {
                    setIsError(true);
                }

            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const [button_bool, setButton_bool] = useState(true);
    const [checked, setChecked] = useState(false);
    const Disable_EnableButton = (event: any) => {
        setChecked(event.target.checked);
        if (event.target.checked === true) {
            setButton_bool(false);
        } else {
            setButton_bool(true);
        }

    };

    useEffect(() => {
        const userType = localStorage.getItem("userType");

       
        if (userType === "admin") {
            props.history.push("/requestmanagement/requestlist");
        } else if (userType === "provider") {
            props.history.push("/providermemberservice/membersearch");
        }


    }, [])
    return (
        <div className="page">
            <SpinnerLoader isGeneral={false} tasksList={['authentication', 'forgotpassword']} inInternal={true} />
            <Header {...props} />

            <div className="form-outer">
                <div>
                    {
                        showforget ?
                            <Paper onSubmit={submitEmail(SendEmail)} component="form" className="signin-form init-form" >
                                <h2>Forget Password</h2>
                                <div className="signin-wrapper">
                                    {
                                        userNotFound ?

                                            <p style={{ color: "#f00" }}>Email not valid or user is not registered</p>
                                            :
                                            ''
                                    }

                                    {
                                        userNotAuthorized ?
                                            <p style={{ color: "#f00" }} >You are not authorized to access the protal. Contact florida dental benefit :  877-674-7901</p>
                                            :
                                            ''

                                    }
                                    <Grid item xs={12} container   >
                                        <Grid item xs={12} sm={12}>
                                            <TextField {...emailforgot("email",
                                                {
                                                    required: "The EmailAddress is required",
                                                    pattern: {
                                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                        message: 'Please Enter a valid EmailAddress',
                                                    },
                                                })}
                                                className="signin-input"
                                                label="Enter email" />
                                            <ErrorMessage
                                                errors={emailerror}
                                                name="email"
                                                render={({ message }) => <p>{message}</p>}
                                            />
                                        </Grid>

                                    </Grid>

                                    <div className="clearfix"></div>
                                    <div className="form-actions forgot-p">
                                        <Button className="button form-button" type="submit" variant="contained" size="small"  >Reset Password</Button>
                                        <Button color="primary" className="forgot-btn" onClick={() => setShowforget(false)} >Back</Button>

                                    </div>
                                </div>
                            </Paper>
                            :

                            showsuccessemail ?
                                <Paper onSubmit={submitEmail(SendEmail)} component="form" className="signin-form init-form" >
                                    <h2>Forget Password</h2>
                                    <div className="signin-wrapper">


                                        <p>Forgot Password Request Initiated. <br /> Check your email address</p>

                                        <div className="form-actions">

                                            <Button color="primary" className="button form-button" onClick={() => setShowsuccessemail(false)} >Back</Button>

                                        </div>
                                    </div>
                                </Paper>
                                :
                                <Paper onSubmit={submitUser(submit)} component="form" className="signin-form init-form" >
                                    <h2>Sign In</h2>
                                    <div className="signin-wrapper">
                                        {
                                            userNotFound ?

                                                <p className="error-msg">Email not valid or user is not registered</p>
                                                :
                                                ''
                                        }

{
                                        userNotAuthorized ?
                                            <p style={{ color: "#f00" }} >You are not authorized to access the protal. Contact florida dental benefit :  877-674-7901</p>
                                            :
                                            ''

                                    }
                                        <Grid item xs={12} container>
                                            <Grid item xs={12} sm={12}>
                                                <TextField {...loginRegister("username",
                                                    {
                                                        required: "The EmailAddress field is required",
                                                        pattern: {
                                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                            message: 'Please enter a valid EmailAddress',
                                                        },
                                                    })}
                                                    className="signin-input"
                                                    label="Enter Email" />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="username"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField {...loginRegister("password",
                                                    {
                                                        required: "The Password field is required",
                                                        pattern: {
                                                            value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[!@_-~?#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@_-~?#$%^&*]{8,25}$/,
                                                            message: '',
                                                        },
                                                    })} type="password" className="signin-input" label="Enter Password" />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </Grid>
                                        </Grid>

                                        <div className="clearfix"></div>
                                        <div className="form-actions">
                                            <InputGroup className="check-wrap signin-check-wrap">
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={Disable_EnableButton}
                                                />
                                                <div className="check-text">I agree to the FloridaDentalBenefits <a onClick={handleShow} href="javascript:void(0);">Terms and Conditions</a></div>
                                            </InputGroup>
                                            <div className="forgot-password">  <Button color="primary" onClick={forgetPassword} >Forgot Password?</Button> </div>
                                            <Button disabled={button_bool || !isValid || !isDirty} className="button form-button" type="submit" variant="contained" size="small"  >Sign In</Button>
                                        </div>
                                    </div>
                                </Paper>

                    }

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header>
                            <Modal.Title>Terms and Conditions</Modal.Title>
                            <Button className="closepopup" onClick={handleClose}>x</Button>
                        </Modal.Header>
                        <Modal.Body>As the authorized user, I hereby submit this attestation to
                            comply with applicable provisions of the Administrative Simplification
                            provisions of the Health Insurance Portability and Accountability Act
                            of 1996 ("HIPAA") as amended by the Health Information Technology for
                            Economic and Clinical Health Act ("HITECH") (enacted as part of the
                            American Recovery and Reinvestment Act of 2009) and the Affordable Care
                            Act ("ACA") (Public Law Nos. 111-148 and 111-152, enacted in March 2010)
                            and the standards, operating rules, and related regulations and guidance
                            promulgated thereunder (referred to collectively, hereinafter, as "the HIPAA
                            requirements"), as may be amended from time to time.</Modal.Body>

                    </Modal>

                    {/* <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Header>
                            <Modal.Title>Forgot Password</Modal.Title>
                        </Modal.Header>
                        <Paper onSubmit={submitEmail(SendEmail)} component="form" className="forget-form" >
                            <div className="row">
                                <div className="row" >
                                    <div  >
                                        <FormLabel component="legend">Enter the email address you signed up with and we'll email you a Confirmation link.</FormLabel>

                                    </div>
                                    <div className="mt-2" >
                                        <InputBase
                                            className="signin-input"
                                            {...email("email")}
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>
                                <Divider orientation="horizontal" />
                                <Button type="submit" variant="contained" size="large" color="primary" >Send Email</Button>
                            </div>
                        </Paper>
                    </Modal> */}
                </div>
                <div>
                    <Announcement />
                </div>

            </div>
            <Footer {...props} />
        </div >


    );
};
export default SignIn;