import React, { Component, useEffect } from 'react'
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom'
import MasterHOC from '../../hoc/MasterHOC';
import EditProfile from './edit-profile/EditProfile';
import  ProviderChangePassword from './provider-change-password/ProviderChangePassword';
function ProviderManagement(props: any) {
    let { path, url } = useRouteMatch();
    useEffect(() => {
        const userType = localStorage.getItem("userType");
       
        if (userType === "admin" && (path === '/' || path === '/provider')) {
            props.history.push("/requestmanagement/requestlist");
        } else if (userType === "provider" && (path === '/' || path === '/admin')) {
            props.history.push("/providermemberservice/membersearch");
        }
       

    }, [])

    return (
        <div className="right-sec-wrapper">

            <div className="row m-0" >
                <div className="col-2 p-0">
                    <div className="left-nav-bx" style={{ height: 800 }}>
                        <nav className="navbar " >
                            <ul className="navbar-nav left-m">
                                <li className="nav-item">
                                    <h6>
                                        <NavLink className="nav-link " to={`${url}/editprofile`}>
                                           Edit Profile <span></span>
                                        </NavLink>
                                    </h6>
                                </li>

                                <li className="nav-item">
                                    <h6>
                                        <NavLink className="nav-link " to={`${url}/changepassword`}>
                                            Change Password <span></span>
                                        </NavLink>
                                    </h6>
                                </li>
                              
                            </ul>
                        </nav>
                    </div>

                </div>


                <div className="col-10 p-5 right-sec">
                    <Switch>
                        <Route exact path={path}>
                            <div>
                                <h3 >Welcome to Provider page</h3>
                            </div>
                        </Route>
                        <Route path={`${path}/editprofile` }  component={EditProfile}/>
                        <Route path={`${path}/changepassword`}  component={ProviderChangePassword} />
                    </Switch>
                </div>

            </div>
        </div>
    )
}

export default MasterHOC(ProviderManagement)
