import './../../matrial-styles/component-styles/SpinnerLoader.component.scss';
import { TaskManager } from '../../managers/Task.manager'
import React, { useLayoutEffect, useEffect, useRef, useState } from "react";

export default function SpinnerLoader({ isGeneral, tasksList, inInternal = false }: any) {
    const elRef = useRef<HTMLDivElement>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (elRef.current !== null) {
        }
    });
    useEffect(() => {
        const taskManager = TaskManager.getInstance();
        taskManager.taskChangeEmitter.subscribe((action: any) => {
            const taskListFiltered = taskManager.getTaskList(tasksList);
            setShowLoading(!!taskListFiltered.find(x => x.loading));

            const spinnerContainerRef = elRef.current?.parentElement;

            if (spinnerContainerRef) {
                if (!isGeneral) {
                    if (showLoading)
                        spinnerContainerRef.style.display = 'block'
                    if (showLoading) {
                        const activeElement = document.activeElement && document.activeElement as HTMLElement;
                        activeElement?.blur();
                    }
                }

                if(inInternal)
                    spinnerContainerRef.classList.add("spinner-container");
                else
                    elRef.current.classList.add("spinner-full-width");

            }
        });
    }, []);

    return (
        showLoading ?
            (
                <div ref={elRef} className={`${!isGeneral ? 'spinner-wrapper-internal' : 'spinner-wrapper'}`}>

                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            ) : null
    )
}