import React, { useEffect } from 'react'
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom'
import MasterHOC from '../../../hoc/MasterHOC';
import RequestList from './request-list/RequestList';
function RequestManagement(props:any) {
  useEffect(() => {
    props.history.push("/requestmanagement/requestlist");
  }, [])
    let { path, url } = useRouteMatch();
    return (
      <div className="right-sec-wrapper">
      
      <div className="row m-0" >
        <div className="col-2 p-0">
         <div className="left-nav-bx"  style={{height:820}} >
         <nav className="navbar " >
              <ul className="navbar-nav left-m">
                <li className="nav-item active-page">
                  <h6>
                    <NavLink activeClassName="active" className="nav-link " to={`${url}/requestlist`}>
                      Request List <span></span>
                    </NavLink>
                  </h6>
                </li>

              </ul>
            </nav>
         </div>
         
          </div>


        <div className="col-10 p-5 right-sec">
          <Switch>
            <Route path={`${path}/requestlist`} component={RequestList} />
          </Switch>
        </div>

      </div>
    </div>
    )
}

export default MasterHOC(RequestManagement)
