import React from 'react'
import ChangePassword from '../../../components/change-password/ChangePassword'
import SpinnerLoader from '../../../components/spinner-loader/SpinnerLoader.component'

function AdminChangePassword(props:any) {
    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['changepassword']} inInternal={true} />
            <ChangePassword {...props} />
        </div>
    )
}

export default AdminChangePassword
