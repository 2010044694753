import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Utili from '../../utili/Utili';
import { DocumentService } from '../../service/DocumentService'

const Documents: any = [];
function PublicDocuments() {
    const [documents, setDocuments] = useState(Documents);
    const [mydocuments, setMyDocuments] = useState(Documents);


    const GetDocument = (id: any) => {
        DocumentService.GetDocument(id).then((res) => {
           // console.log(res);
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const ViewDocument = (id: any) => {
        
        DocumentService.ViewDocument(id).then((res) => {
            console.log(res);
            window.open(URL.createObjectURL(res.data));
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const Download = (pdfBytes: any) => {
        var blob = Utili.b64toBlob(pdfBytes);
        var url = URL.createObjectURL(blob);
        // window.open(url);
        let a = document.createElement("a");
        a.href = url;
        a.download = "RevenueReport";
        a.click();
    };
    const GetAllDocument = () => {
        DocumentService.GetPublicDocumentList().then((res) => {
            setDocuments(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const GetMyDocuments = () => {
        DocumentService.GetMyDocumentList().then((res) => {
            setMyDocuments(res.data);
            console.log(true);
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        //GetAllDocument();
        GetMyDocuments();

    }, [])
    return (
        <>

            {/* <div className="puclic-document-container">
                <h4>Documents</h4>


                <div className="row">

                    {
                        documents.map((item: any, index: any) => {
                            if (documents.length === 1) {
                                return (
                                    <div key={index} className="document-single-categories-container" >
                                        <h5 className="heading-5">{item.name}</h5>
                                        <div className="row">
                                            <div className="doc-wrapper">
                                                {
                                                    item.documents.map((c: any, i: any) => (
                                                        <div key={i}  >
                                                            <div className="doc-div">
                                                                <div className="doc-icon"></div>
                                                                <Button style={{ marginTop: 10 }}
                                                                    onClick={() => ViewDocument(c.id)}
                                                                    size="small" className=""
                                                                    variant="text" >
                                                                    {c.fileName}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index} className="document-categories-container" >
                                        <h5 className="heading-5">{item.name}</h5>
                                        <div className="row">
                                            <div className="doc-wrapper">
                                                {
                                                    item.documents.map((c: any, i: any) => (
                                                        <div key={i}  >
                                                            <div className="doc-div">
                                                                <div className="doc-icon"></div>
                                                                <Button style={{ marginTop: 10 }}
                                                                    onClick={() => ViewDocument(c.id)}
                                                                    size="small" className=""
                                                                    variant="text" >
                                                                    {c.fileName}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }

                </div>
            </div> */}

            <div className="puclic-document-containers  ">
                <h4> My Documents</h4>


                <div className="row">

                    {
                        mydocuments.map((item: any, index: any) => {
                            if (mydocuments.length === 1) {
                                return (
                                    <div key={index} className="document-single-categories-container" >
                                        <h5 className="heading-5">{item.name}</h5>
                                        <div className="row">
                                            <div className="doc-wrapper">
                                                {
                                                    item.documents.map((c: any, i: any) => (
                                                        <div key={i}  >
                                                            <div className="doc-div">
                                                                <div className="doc-icon"></div>
                                                                <Button style={{ marginTop: 10 }}
                                                                    onClick={() => ViewDocument(c.id)}
                                                                    size="small" className=""
                                                                    variant="text" >
                                                                    {c.fileName}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index} className="document-categories-container" >
                                        <h5 className="heading-5">{item.name}</h5>
                                        <div className="row">
                                            <div className="doc-wrapper">
                                                {
                                                    item.documents.map((c: any, i: any) => (
                                                        <div key={i}  >
                                                            <div className="doc-div">
                                                                <div className="doc-icon"></div>
                                                                <Button style={{ marginTop: 10 }}
                                                                    onClick={() => ViewDocument(c.id)}
                                                                    size="small" className=""
                                                                    variant="text" >
                                                                    {c.fileName}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }

                </div>
            </div>
        </>
    )
}

export default PublicDocuments
