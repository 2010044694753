import http from "./http-common";

const GetPublicDocumentList = () => {
    return http.get("api/1/Document/GetPublicDocumentList", {
        headers: {'task_name': 'publicdocument'}
    });
}

const GetAllDocumentList = () => {
    return http.get("api/1/Document/GetAllDocumentList2", {
        headers: {'task_name': 'GetAllDocumentList'}
    });
}

const GetMyDocumentList = () => {
    return http.get("api/1/Document/GetMyDocumentList", {
        headers: {'task_name': 'publicdocument'}
    });
}

const UpdateDocument = (data : any) => {
    return http.post("api/1/Document/UpdateDocument", data,{
        headers: {'task_name': 'UpdateDocument'}});
}

const CreateDocument = (data : any) => {
    return http.post("api/1/Document/CreateDocument", data,{
        headers: {'task_name': 'CreateDocument'}});
}

const UpdateAnnouncement = (Announcement:any,Heading:any) => {
    return http.post(`api/1/Announcement/UpdateAnnouncement?Announcement=${Announcement}&Heading=${Heading}`, {
        headers: {'task_name': 'updateAnnouncement'}
    });
}

const GetDocument = (id :any) => {
    return http.get(`api/1/Document/GetPublicDocumentById/${id}`, {
        headers: {'task_name': 'getdocument'}});
}

const ViewDocument = (id :any) => {
    return http.get(`api/1/Document/ViewDocument?Id=${id}`, {
        responseType: 'blob',
        headers: {'task_name': 'getdocument',
                 'Content-Type': 'application/pdf'}
    });
}

const DeleteDocument = (id :any) => {
    return http.post(`api/1/Document/DeleteDocument?Id=${id}`, {
        headers: {'task_name': 'getdocument'}});
}

const ViewAnnouncement = () => {
    return http.get(`api/1/Announcement/ViewAnnouncement`, {
        headers: {'task_name': 'ViewAnnouncement'}});
}

export const DocumentService = {
    UpdateDocument,
    ViewDocument,
    DeleteDocument,
    GetAllDocumentList,
    CreateDocument,
    GetMyDocumentList,
    ViewAnnouncement,
    UpdateAnnouncement,
    GetPublicDocumentList,
    GetDocument,

};