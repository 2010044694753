import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/matrial-styles/FormStyles.scss';
import '../src/matrial-styles/ButtonStyles.scss';
import '../src/matrial-styles/InputStyles.scss';
import '../src/matrial-styles/CommonStyles.scss';
import '../src/matrial-styles/component-styles/ComponentStyles.scss'
import './App.css';

// public Pages
import Home from './pages/home/Home';
import SignIn from './pages/sign-in/SignIn.page';
import Confirmation from './pages/forgot-password/confirm-email/ConfirmEmail';
import SignUp from "./pages/signup/SignUp.page";

// AdminManagment Pages
import AdminManagement from "./pages/admin-management/AdminManagement";
import RequestManagement from "./pages/admin-management/request-management/RequestManagement";
import UserManagement from "./pages/admin-management/user-management/UserManagement";
import DocumentManagement from "./pages/admin-management/document-&-announcement/Document&Announcement";
import AdminMemberService from "./pages/admin-management/admin-member-service/AdminMemberService";

// ProviderManagment Pages
import ViewDocument from './pages/provider-management/view-document/ViewDocument';
import ProviderMemberService from './pages/provider-management/provider-member-service/ProviderMemberService';
import ProviderManagement from './pages/provider-management/ProviderManagement';
import { ToastContainer } from "react-toastify";

function App(props: any) {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}


      <Router>
        <Switch>
        <Route path="/viewdocument" component={ViewDocument} />
          <Route path="/providermemberservice" component={ProviderMemberService} />
          <Route path="/provider" component={ProviderManagement} />
          <Route path="/adminmemberservice" component={AdminMemberService} />
          <Route path="/documentmanagement" component={DocumentManagement} />
          <Route path="/usermanagement" component={UserManagement} />
          <Route path="/requestmanagement" component={RequestManagement} />
          <Route path="/admin" component={AdminManagement} />
          <Route path="/ForgotPassword" component={Confirmation} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
