import { saveAs } from 'file-saver';
import { ErrorMessage } from '@hookform/error-message';
import { Button, FormControl, InputLabel, MenuItem, Select, Box, Grid, TextField } from '@material-ui/core';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import MemberService from '../../service/MemberService';
import SpinnerLoader from '../spinner-loader/SpinnerLoader.component';
import ReactDOM from 'react-dom';
import './MemberSearch.scss';
import { isNamespaceExport } from 'typescript';
import ProviderManagement from '../../pages/provider-management/ProviderManagement';

const focusclose = createTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    background: "$labelcolor"
                }
            }
        }
    }
});
const GridList: any = [];

const initialState = {
    dateofService: "",
    officeCode: "",


};

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
    flexDirection: 'column',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));



const selectedMember = {
    memberId: 0,
    memberCode: '',
    EffDate: '',
    From: '',
    officeName: '',
    memberName: '',
    planName: '',
    groupName: '',
    officeId: 0,
    officeCode: '',
    DateService: ''
};


function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>{Notfound}</Box>
        </StyledGridOverlay>
    );
}
var Notfound = "";
function MemberSearch(props: any) {
    const [list, setList] = useState(GridList);
    const [Claimlist, seClaimtList] = useState(GridList);
    const [officelist, setOfficeList] = useState(GridList);
    const [notfound, setnotfound] = useState("Search Member");
    Notfound = notfound;
    const columns = [
        {
            field: 'memberCode',
            headerName: 'Members ID',
            width: 150,
            filterable: false,
        },
        {
            field: 'fullName',
            headerName: 'Members name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 260,
            valueGetter: (params: any) =>
                `${params.getValue(params.row.id, 'firstName') || ''} ${params.getValue(params.row.id, 'lastName') || ''
                }`,
        },

        {
            field: 'dateofBirth',
            headerName: 'Date of Birth',
            width: 120,

        },

        {
            field: 'effectiveDate',
            headerName: 'Effective Date',
            width: 150,

        },

        {
            field: 'terminationDate',
            headerName: 'Termination Date',
            width: 150,

        },

        {
            field: 'planName',
            headerName: 'Health Plan',
            flex: 1,
            width: 200,
            operatorValue: 'contains',

        },





        {
            field: '',
            headerName: '',

            width: 80,
            filterable: false,
            sortable: false,
            renderCell: (params: any) => (
                <Button
                    className="view-popup"
                    onClick={() => viewrequest(list.find((e: any) => e.id == params.row.id))}

                >View</Button>
            ),

        },
    ];

    const columnsClaims = [
        {
            field: 'claimNo',
            headerName: 'Claim#',
            width: 80,
            filterable: false,
        },

        {
            field: 'procDesc',
            headerName: 'Procedurecode',
            flex: 1,
            width: 250,
            operatorValue: 'contains',

        },
        {
            field: 'dateClosed',
            headerName: 'PaymentDate',
            width: 120,

        },

        {
            field: 'rsnDesc',
            flex: 1,
            headerName: 'Approval/denialCode',
            width: 250,

        },

        {
            field: 'preAuthRec',
            headerName: 'PreauthDate',
            width: 120,

        },

        {
            field: 'dos',
            headerName: 'DOS',
            width: 110,

        },
        {
            field: 'surface',
            headerName: 'Surface',
            width: 70,
            renderCell: (params: any) => (
                <div>
                    {params.row.surface}
                </div>


            ),

        },
        {
            field: 'tooth',
            headerName: 'Tooth',
            width: 70,
            renderCell: (params: any) => (
                <div>
                    {params.row.tooth}
                </div>


            ),

        },


        {
            field: 'billed',
            headerName: 'Billed',
            width: 70,
            renderCell: (params: any) => (
                <div>
                    $  {params.row.billed}
                </div>


            ),

        },

        {
            field: 'coPayment',
            headerName: 'CoPayment',
            width: 110,
            renderCell: (params: any) => (
                <div>
                    $  {params.row.coPayment}
                </div>


            ),

        },

        {
            field: 'totalPayment',
            headerName: 'TotalPayment',
            width: 125,
            renderCell: (params: any) => (
                <div>
                    $  {params.row.totalPayment}
                </div>
            ),

        },
    ];
    const [IsPrintable, setIsPrintable] = useState(false);
    const [bool, setbool] = useState(false);
    const [search, setsearch] = useState<any>();
    const [showviewrequest, setShowviewrequest] = useState(false);
    const [ValidateOfficeName, setValidateOfficeName] = useState<any>();
    const [ValidateOffice, setValidateOffice] = useState<any>();
    const [ValidateService, setValidateService] = useState<any>();
    const [ValidatedMember, setValidatedMember] = useState([{}]);
    const [isValidated, setisValidated] = useState(false);
    const [isClaimInfo, setisClaimInfo] = useState(false);
    const [isOffice, setisOffice] = useState(false);

    const {
        register: searchMember,
        formState: { errors: errorsAddAdmin },
        handleSubmit: SubmitSearch,
        reset: resetSearch,
        watch,

    } = useForm();

    const {
        register: officevalidate,
        control,
        handleSubmit,
        reset: resetValidate,
        getValues,
        formState: { errors },
    } = useForm();

    const onSearch = (search: any) => {
        setisValidated(false);
        if (search.memberCode === "") {
            delete search.memberCode;
        }
        if (search.middleName === "") {
            delete search.middleName;
        }
        MemberService.memberSearch(search).then((res) => {
            setisOffice(false);

            if (res.data.length === 0) {
                setnotfound("Member Not found. Contact FDBenefits  877-674-7901")

                setList(res.data);
            } else {
                setbool(true);
                setisValidated(false);
                setList(res.data);

            }

        }).catch((err: any) => {
            console.log(err);
        });

    };

    const viewrequest = (user: any) => {
        GetMemberClaimHistory(user.memberId)
        resetValidate({});
        setMemberuser(user);
        setShowviewrequest(true);

    }

    const printMemberProfile = async (member: any) => {
        console.log('print file clicked : ' + member.id);
        let providerName = localStorage.getItem("userFirstName") + ' ' + localStorage.getItem("userLastName");
        MemberService.notifyMemberValidation(member.memberCode, ValidateOffice, ValidateService).then((res) => {

            if (res.data.length === 0) {
                console.log(res.data);
            } else {
                console.log(res.data);
                window.open(URL.createObjectURL(res.data));
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const GetMemberClaimHistory = (id: any) => {
        MemberService.memberCliamHistory(id).then((res) => {
            if (res.data.length === 0) {

                console.log(res.data);
                seClaimtList(res.data);


            } else {
                seClaimtList(res.data);


            }


        }).catch((err) => {
            console.log(err);
        });

    }

    const submit = (data: any) => {
        resetSearch({
            firstName: "",
            middleName: "",
            lastName: "",
            dateofBirth: "",
            memberCode: ""
        });

        setValidateOffice(data.officeCode);
        setValidateService(data.dateofService);
        MemberService.validateMember(data).then((res) => {
            if (res.data.length === 0) {

                setisOffice(true);
            } else {
                setbool(false);
                setShowviewrequest(false);
                setisValidated(true);
                setValidatedMember(res.data)
                if (res.data[0].isPlanActive) {
                    if (res.data[0].memberOfficeStatus) {
                        setIsPrintable(res.data[0].memberOfficeStatus);
                    }
                    else {
                        setIsPrintable(false);
                    }
                }
                else {
                    setIsPrintable(false);
                }

                console.log(res.data[0].memberOfficeStatus)

            }

        }).catch((err) => {
            console.log(err);
        });

    };


    const myOffices = () => {
        MemberService.providerOffices().then((res) => {

            setOfficeList(res.data);
        }).catch((err) => {
            console.log(err);
        });


    };

    const OfficeName = (data: any) => {
        console.log(data)

    };
    const [memberuser, setMemberuser] = useState({});

    const uploadClaim = async () => {
        const accessToken = await MemberService.getUploadClaimAccessToken();
        if (accessToken) {
            window.open(`https://providers.fdb.32health.care/upload-claim?access_token=${accessToken.data}`,'_blank');
            console.log(accessToken.data)
        } else {
            alert('Unable to open the portal');
        }
    }

    useEffect(() => {
        myOffices();
    }, [])
    return (
        <div>
            <SpinnerLoader isGeneral={false} tasksList={['memberSearch', 'validatemember', 'provideroffices', 'memberCliamHistory']} inInternal={true} />
            <h3>Member Search</h3>
            <form onSubmit={SubmitSearch(onSearch)} >
                <div className="form-container change-passwrod member-search">

                    <Grid className="form-group" item xs={12} sm={12} spacing={2}>
                        <Grid className="form-group form-group2" item xs={12} sm={12}>
                            <TextField {...searchMember("firstName",
                                {
                                    required: "The FirstName is required",
                                })}

                                className="form-input"
                                label="First Name (full first name required)"
                            />
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="firstName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                        </Grid>

                        <Grid className="form-group middle-g" item xs={12} sm={7}>
                            <TextField {...searchMember("middleName")}
                                className="form-input"

                                label="Middle Initial"
                            />
                        </Grid>

                        <Grid className="form-group form-group2" item xs={12} sm={7}>

                            <TextField

                                {...searchMember("lastName",
                                    {
                                        required: "The LastName is required",
                                    })}
                                className="form-input"

                                label="Last Name (full last name required)"
                            />
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="lastName"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                        </Grid>


                    </Grid>
                    <Grid className="form-group" item xs={12} sm={12}>
                        <Grid className="form-group form-group2" item xs={12} sm={12}>

                            <TextField {...searchMember("dateofBirth",
                                {
                                    required: "The Date of Birth is required",
                                    maxLength: {
                                        value: 10,
                                        message: 'Enter a Valid Date',
                                    },

                                })}

                                className="form-input"
                                label="Date of Birth *"
                                focused
                                type="date"
                                InputProps={{ inputProps: { min: "1900-01-01", max: "2999-00-00" } }}
                            />
                            <div className="popup-error">
                                <ErrorMessage
                                    errors={errorsAddAdmin}
                                    name="dateofBirth"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                        </Grid>

                        <Grid className="form-group  middle-g" item xs={12} sm={12}>

                            <TextField {...searchMember("memberCode",
                            )}
                                className="form-input"
                                label="Member ID"

                            />
                        </Grid>
                        <Grid className="form-group middle-g" item xs={12} sm={12}>
                            <a className="upload-claim" onClick={() => uploadClaim()}>Upload Claim</a>
                        </Grid>
                        <Grid className="form-group" item xs={12} sm={12}>
                        </Grid>
                        <div className="popup-error">
                            <ErrorMessage
                                errors={errorsAddAdmin}
                                name="memberCode"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>
                    </Grid>

                    <Grid className="form-group form-btn-cont-wrap" item xs={12} sm={12}>
                        <div className="form-btn-cont">
                            <Button className="button change-passwrod-btn" type="submit">
                                Search
                            </Button>
                        </div>
                    </Grid>
                </div>

            </form>

            <div className='record-list'>

                {
                    isValidated ?
                        <>

                            <div className='office-code-wrap'>
                                <div>Office Code : {ValidateOffice} {ValidateOfficeName}</div>
                                <div>Date of Service : {ValidateService}</div>

                            </div>
                            <div>
                                <ul className='tab-nav'>
                                    <li onClick={() => setisClaimInfo(false)}>Member Info</li>
                                    <li onClick={() => setisClaimInfo(true)} >Claim History</li>
                                </ul>
                            </div>
                            {
                                isClaimInfo ?
                                    <div className='tab-bx'>
                                        <div className="grid-wrapper announ" style={{ height: 450 }}>
                                            <h4>Claim History</h4>
                                            <DataGrid
                                                rows={Claimlist}

                                                columns={columnsClaims}
                                                getRowId={(row) => row.claimLineItemID}
                                                filterMode='client'
                                                disableDensitySelector={true}
                                                disableColumnSelector={true}
                                                components={{
                                                    NoRowsOverlay: CustomNoRowsOverlay,
                                                }}
                                                pageSize={10}
                                                rowsPerPageOptions={[10]}
                                                disableColumnMenu
                                                headerHeight={50}
                                                rowHeight={38}
                                                disableSelectionOnClick
                                            />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {

                                            ValidatedMember.map((c: any, i: any) => (
                                                <div className='tab-bx'>
                                                    <div className='record-bx' key={i}  >
                                                        {
                                                            c.memberOfficeStatus ?
                                                                ''
                                                                :
                                                                <h4 style={{ color: "#f00" }}>Office is not open to member's plan</h4>
                                                        }
                                                        <h4>Member Info</h4>
                                                        <div className="record-bx-inner">
                                                            <div className="heading-5">
                                                                <div className="t-header">
                                                                    <h5 > Member Info  </h5>
                                                                    <div className='status-bx'> <span> Status: </span>
                                                                        {
                                                                            c.enrollmentStatus ?
                                                                                <p>Active</p>
                                                                                :
                                                                                <p>InActive</p>
                                                                        }</div>

                                                                </div>
                                                            </div>
                                                            <div className="inner-bd">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Member name {c.memberId}:</div>
                                                                            <p>{c.firstName} {c.middleName} {c.lastName}</p>
                                                                        </div>


                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Health Plan :</div>
                                                                            <p>{c.planName}</p>
                                                                        </div>
                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Effective Date :</div>
                                                                            <p>{c.effectiveDate}</p>
                                                                        </div>
                                                                        <div className="t-group">
                                                                            <div className='t-lable '> Office Status: </div>
                                                                            {
                                                                                c.memberOfficeStatus ?
                                                                                    <p>Active</p>
                                                                                    :
                                                                                    <p>InActive</p>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Member Id :</div>
                                                                            <p>{c.memberCode}</p>
                                                                        </div>
                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Date of Birth :</div>
                                                                            <p>{c.dateofBirth}</p>
                                                                        </div>
                                                                        <div className="t-group">
                                                                            <div className='t-lable'>Termination Date :</div>
                                                                            <p>{c.terminationDate}</p>
                                                                        </div>
                                                                        <div className="t-group">
                                                                            <div className='t-lable'> Plan Status :</div>
                                                                            {
                                                                                c.isPlanActive ?
                                                                                    <p>Active</p>

                                                                                    :
                                                                                    <p>InActive</p>
                                                                            }

                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div id="download-pdf-link"><button disabled={IsPrintable ? false : true} id="print-btn" className='print-btn print-button' type='button' onClick={() => printMemberProfile(c)} ><span></span> Print Member Profile</button></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))

                                        }

                                    </>
                            }

                        </>
                        :
                        <>
                            <h3>Members Found</h3>
                            <div className="grid-wrapper" style={{ height: 450 }}>

                                <DataGrid
                                    rows={list}

                                    columns={columns}
                                    filterMode='client'
                                    disableDensitySelector={true}
                                    disableColumnSelector={true}
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                    }}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableColumnMenu
                                    headerHeight={50}
                                    rowHeight={38}
                                    disableSelectionOnClick
                                />
                            </div>
                        </>
                }

                {
                    bool ?
                        <Modal size="xl" className="request-list-modal" show={showviewrequest} onHide={() => setShowviewrequest(false)} backdrop="static" >
                            <Modal.Header className="popup-head">
                                <Button className="closepopup admin" onClick={() => setShowviewrequest(false)}>x</Button>
                                <Modal.Title>Member Validation</Modal.Title>
                            </Modal.Header>


                            <div className="form-container request-list-container">
                                <Grid className="form-group" item xs={12} sm={12}>
                                    <Grid className="" item xs={12} sm={12}>
                                        <Grid className="form-group" item xs={12} sm={10}>

                                            <label>Member name :</label>
                                            <div>{
                                                // @ts-ignore
                                                memberuser.firstName}</div>
                                            <div>{
                                                // @ts-ignore
                                                memberuser.lastName}</div>

                                        </Grid>

                                        <Grid className="form-group" item xs={12} sm={10}>
                                            <label>Health Plan :</label>

                                            <div>{// @ts-ignore
                                                memberuser.planName}</div>
                                        </Grid>

                                        <Grid className="form-group" item xs={12} sm={10}>
                                            <label>Effective Date:</label>
                                            <div>{// @ts-ignore
                                                memberuser.effectiveDate}</div>
                                        </Grid>
                                        {/* <Grid className="form-group" item xs={12} sm={10}>
                    <label>Active Status :</label>
                    <div>{  // @ts-ignore
                        memberuser.officeContactNumber}</div>
                </Grid> */}



                                    </Grid>
                                    <Grid className="" item xs={12} sm={12}>
                                        <Grid className="form-group" item xs={12} sm={10}>

                                            <label>Member ID :</label>
                                            <div>{ // @ts-ignore
                                                memberuser.memberCode}</div>

                                        </Grid>

                                        <Grid className="form-group" item xs={12} sm={10}>
                                            <label>Date of Birth :</label>
                                            <div>{ // @ts-ignore
                                                memberuser.dateofBirth}</div>
                                        </Grid>

                                        <Grid className="form-group" item xs={12} sm={10}>
                                            <label>Termination Date :</label>
                                            <div>{ // @ts-ignore
                                                memberuser.terminationDate}</div>
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid className="form-group" item xs={12} sm={12}>
                                    <input hidden={true}
                                        {...officevalidate("memberCode",)}
                                        defaultValue={ // @ts-ignore
                                            memberuser.memberCode} />
                                    <TextField {...officevalidate("dateofService",
                                        {
                                            required: "The Date of Service is required",
                                            maxLength: {
                                                value: 10,
                                                message: 'Enter a Valid Date',
                                            },
                                        })}
                                        focused
                                        className="form-input"
                                        label="Date of Service *"
                                        type="date"
                                    />
                                </Grid>
                                <div className="popup-error service">
                                    <ErrorMessage
                                        errors={errors}
                                        name="dateofService"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>
                                <Grid className="droplist" item xs={12} sm={5}>
                                    <div className="states-wrapper">

                                        <Controller
                                            name={"officeCode"}
                                            control={control}
                                            rules={{ required: "The Office is required." }}
                                            render={({ field: { onChange, value } }) => (

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Office </InputLabel>
                                                    <ThemeProvider theme={focusclose}>
                                                        <Select

                                                            labelId="demo-simple-select-label"
                                                            value={value}

                                                            //onChange={onChange}
                                                            {...officevalidate("officeCode")}
                                                        >
                                                            {officelist.map((option: any) => {

                                                                if (option.officeCode === value) {
                                                                    setValidateOfficeName(option.officeName)

                                                                }

                                                                return (


                                                                    <MenuItem value={option.officeCode} >

                                                                        {option.officeCode}{option.officeName}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </ThemeProvider>
                                                </FormControl>

                                            )}
                                        />

                                        <ErrorMessage
                                            errors={errors}
                                            name="officeCode"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>

                                </Grid>
                                <div className="popup-error">
                                    <ErrorMessage
                                        errors={errorsAddAdmin}
                                        name="memberCode"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>



                                <Grid className="form-group form-buttons" item xs={12} sm={12}>

                                    <Button className="button popup-button" onClick={handleSubmit(submit)}>
                                        Validate
                                    </Button>

                                </Grid>
                            </div>

                        </Modal>
                        :
                        ''

                }

            </div>


        </div>
    )
}

export default MemberSearch
