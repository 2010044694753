import React, { useEffect, useState } from 'react'
import Utili from '../../utili/Utili';
import { AuthService } from '../../service/AuthService';

const Sessiontimeout = (props:any) =>  {
   
        const [signoutTime, setSignoutTime] = useState(1000 * 60* 15 );
        const [warningTime, setWarningTime] = useState(1000 * 60 * 14);
        let warnTimeout :any ;
        let logoutTimeout :any ;

        const warn = () => {
          
                Utili.SessionTimeOut();
                console.log('Warning');
         
            
        };
        const logout = () => {
            AuthService.logout(props);
            console.log('You have been loged out');
        }

        const destroy = () => {
            console.log('Session destroyed');
        }
        const setTimeouts = () => {
            warnTimeout = setTimeout(warn, warningTime);
            logoutTimeout = setTimeout(logout, signoutTime);
        };

        const clearTimeouts = () => {
            if (warnTimeout) clearTimeout(warnTimeout);
            if (logoutTimeout) clearTimeout(logoutTimeout);
        };

        useEffect(() => {

            const events = [
                'load',
                'mousemove',
                'mousedown',
                'click',
                'scroll',
                'keypress'
            ];

            const resetTimeout = () => {
                clearTimeouts();
                setTimeouts();
            };

            for (let i in events) {
                window.addEventListener(events[i], resetTimeout);
            }

            setTimeouts();
            return () => {
                for(let i in events){
                    window.removeEventListener(events[i], resetTimeout);
                    clearTimeouts();
                }
            }
        },[]);


  
}

export default Sessiontimeout
