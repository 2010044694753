import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import state from './state.json';
import './EditProfile.scss';

import UserService from '../../service/UserService';
import Utili from '../../utili/Utili';
import SpinnerLoader from '../spinner-loader/SpinnerLoader.component';
function EditableProfile() {
    const [profile, setprofile] = useState({});
    const
        {
            register: UserEdit,
            control, watch,
            formState: { errors, isValid, isDirty },
            handleSubmit: submitUser,
            reset: resetprofile,
        } = useForm({ mode: "onChange" });

    const onSubmit = (updateuser: any) => {
       

        UserService.updateProvider(updateuser).then((res) => {
           Utili.notifyUpdated();
           localStorage.setItem("userFirstName", updateuser.firstName);
           localStorage.setItem("userLastName",updateuser.lastName);
          
        })
            .catch((err) => {
                console.log(err);
            });
       
    };

    useEffect(() => {
        UserService.getProviderProfile().then((res) => {
            setprofile(res.data);
            resetprofile({
                id :  res.data.id,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                email: res.data.email,
                officeName: res.data.officeName,
                npi: res.data.npi,
                taxID: res.data.taxID,
                addressLine1: res.data.addressLine1,
                addressLine2: res.data.addressLine2,
                city: res.data.city,
                state: res.data.state,
                zipCode: res.data.zipCode,
                officeContactNumber: res.data.officeContactNumber,
            });
           
        }) .catch((err) => {
            console.log(err);
        });
    }, [])

    return (
        <div>
             <SpinnerLoader isGeneral={false} tasksList={['updateprovider']} inInternal={true} />
            <h3>Edit Profile </h3>
            <Paper onSubmit={submitUser(data => onSubmit(data))} component="form" className="signup-form edit-profile"  >



                <Grid item xs={7} container spacing={2}  >
                <input  {...UserEdit("id",)} hidden={true} />
                    <Grid item xs={12} sm={6}>
                        <TextField

                            className="signup-input"
                            {...UserEdit("firstName",
                                {
                                    required: "The FirstName is required",
                                    pattern: {
                                        value: /^[aA-zZ\s]+$/i,
                                        message: 'This input is alphabet only.',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: 'This input exceed maxLength.',
                                    },

                                })}

                            label="First Name"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="firstName"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <TextField
                            className="signup-input"
                            {...UserEdit("lastName",
                                {
                                    required: "The LastName is required",
                                    pattern: {
                                        value: /^[aA-zZ\s]+$/i,
                                        message: 'This input is alphabet only.',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: 'This input exceed maxLength.',
                                    },

                                })}

                            label="Last Name"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="lastName"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("email",
                                {
                                    required: "The EmailAddress is required.",
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                        message: 'Email Pattern does not match',
                                    },


                                })}

                            label="Email address"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("officeName",
                                {
                                    required: "The OfficeName is required."


                                })}

                            label="Office Name"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="officeName"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <TextField
                            multiline

                            rows={3}
                            className="signup-input txta"
                            {...UserEdit("taxID",
                                {
                                    required: "The TaxID is required.",
                                    pattern: {
                                        value: /^.{0,8}[0-9](?:\r?\n.{0,9}){0,20}$/i,
                                        message: 'Enter valid TaxId.',
                                    },

                                })}

                            label="Tax ID"
                            type="number"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="taxID"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("npi")}

                            label="NPI"
                            disabled
                        />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("addressLine1",
                                {
                                    required: "The Address1 is required."

                                })}

                            label="Physical Address 1"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="addressLine1"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("addressLine2")}

                            label="Physical Address 2"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="addressLine2"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className="signup-input"
                            {...UserEdit("city",
                                {
                                    required: "The City is required."

                                })}

                            label="City"
                            disabled
                        />
                        <ErrorMessage
                            errors={errors}
                            name="city"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="states-wrapper">

                            <Controller
                                name={"state"}
                                control={control}
                                rules={{ required: "The State is required." }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          State :  {//@ts-ignore
                                        profile.state
                                        }</InputLabel>
                                        <Select
                                            defaultValue={
                                                //@ts-ignore
                                                profile.state
                                            }
                                            labelId="demo-simple-select-label"
                                            value={value}
                                            // onChange={onChange}
                                            {...UserEdit("state", {
                                                required: "select one option"
                                            })}
                                            disabled
                                        >
                                            {state.map((option: any) => {
                                                return (
                                                    <MenuItem value={option.Code}>
                                                        {option.State}
                                                    </MenuItem>
                                                )
                                            })}
                                            
                                        </Select>
                                    </FormControl>
                                )}
                               
                            />
                            <ErrorMessage
                                errors={errors}
                                name="state"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField

                            className="signup-input"

                            {...UserEdit("zipCode",
                                {
                                    required: "The ZipCode is required.",
                                    maxLength: {
                                        value: 10,
                                        message: 'This input exceed maxLength.',
                                    },

                                })}

                            label="Zip Code"
                            type='number'
                            disabled

                        />
                        <ErrorMessage
                            errors={errors}
                            name="zipCode"
                            render={({ message }) => <p>{message}</p>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                       

                        <Controller
                            control={control}
                            rules={{ required: "The OfficePhoneNumber is required." }}
                            name="officeContactNumber"
                            render={({ field: { onChange, name, value } }) => (
                                <NumberFormat
                                    className="signup-input"
                                    label="Office Phone number"
                                    format="(###) ###-####"
                                    name={name}
                                    customInput={TextField}
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                />
                                
                            )}
                           
                        />
                        
                        <ErrorMessage
                            errors={errors}
                            name="officeContactNumber"
                            render={({ message }) => <p>{message}</p>}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <div className="note-text">
                                <p><strong> Note:</strong> To make changes to your profile on the Provider Portal please contact Provider Relations via email: <a href="mailto:providerrelations@fdbenefits.com">providerrelations@fdbenefits.com</a>  or by phone:  877-674-7901 X998.</p>
                           
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <div className="p-ft">

                            <Button size="small" disabled={!isValid || !isDirty} className="button form-button" type="submit" variant="contained" >

                                Submit

                            </Button>
                        </div>
                    </Grid>
                </Grid>




            </Paper>
        </div>
    )
}

export default EditableProfile
