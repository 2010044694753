import http from "./http-common";

const login = (user: any, props: any) => {
     return http.post("auth/1/Authentication/authorize", user, {
        headers: {'task_name': 'authentication'}
    }
    );
}

const forgotPassword = (email: any) => {
    return http.post("user/1/Users/ForgotPassword", email, {
        headers: {'task_name': 'forgotpassword'}});
}

const confirmEmail = (otp: any) => {
    return http.post("user/1/Users/SubmitSignupRequestOTP", otp, {
        headers: {'task_name': 'confirmemail'}});
}

const confirmForgotPassword = (email: any) => {

    return http.post("user/1/Users/ValidateForgotPasswordRequest" + email, {
        headers: {'task_name': 'conficonfirmforgotpasswordrmemail'}});
}

const changeForgotPassword = (email: any, data: any) => {

    return http.post("user/1/Users/ChangeForgotPasswordRequest" + email, data,{
        headers: {'task_name': 'changeforgotpassword'}});
}
const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (token === null || token === "null" || token === "") {
        return false;
    } else {
        return true;
    }
}

const logout = (props: any) => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    localStorage.removeItem("userFirstName");
    localStorage.removeItem("userLastName");
    localStorage.removeItem("signinUserEmail");
    props.history.push("/");
}

export const AuthService = {
    changeForgotPassword,
    confirmForgotPassword,
    checkAuth,
    login,
    logout,
    forgotPassword,
    confirmEmail,
};
