
import { Observable, Subject } from 'rxjs';
import { Task } from './../models/Task.model'


export class TaskManager {

    static instance: TaskManager;
    static createInstance() {
        var object = new TaskManager();
        return object;
    }

    static getInstance() {
        if (!TaskManager.instance) {
            TaskManager.instance = TaskManager.createInstance();
        }
        return TaskManager.instance;
    }
    //static instance = this.instance || new TaskManager()

    taskList: Array<Task> = [];
    private taskChange: Subject<boolean> = new Subject<boolean>();
    public taskChangeEmitter: Observable<boolean> = this.taskChange.asObservable();

    addTask(task: Task) {
        const taskIndex = this.taskList.findIndex((existingTask) => existingTask.id === task.id);
        if (taskIndex === -1) {
            task.count = 1;
            this.taskList.push(task);
        } else {
            this.taskList[taskIndex].count++;
        }
        this.taskChange.next(true);
    }

    updateTask(id: string, task: Task) {
        const taskIndex = this.taskList.findIndex((existingTask) => existingTask.id === id);
        this.taskList[taskIndex] = task;
        this.taskChange.next(true);
    }

    removeTask(id: string) {
        const taskIndex = this.taskList.findIndex((existingTask) => existingTask.id === id);
        if (taskIndex >= 0) {

            this.taskList[taskIndex].count--;
            if (this.taskList[taskIndex].count === 0) {
                this.taskList.splice(taskIndex, 1);
            }
            this.taskChange.next(false);
        }
    }

    getTask(id: string): Task | null {
        const taskIndex = this.taskList.findIndex((existingTask) => existingTask.id === id);
        if (taskIndex != -1) {
            return this.taskList[taskIndex];
        } else {
            return null;
        }
    }

    getTaskList(list: string[]): Array<Task> {
        let a = this.taskList.filter(x => {
            return list.includes(x.id);
        });
        return a;
    }
}