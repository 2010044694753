import http from "./http-common";

const getProviderProfile = () => {
  return http.post(`user/1/Users/MyProfile` , {
    headers: {'task_name': 'getproviderprofile'}});
};

const ProviderSignUP = (provider: any) => {
  return http.post("user/1/Users/CreateProviderSignUpRequest", provider, {
    headers: {'task_name': 'providersignup'}});
}

const ApproveRejectSignUpRequest = (request: any) => {
  return http.post("user/1/Users/ApproveRejectSignUpRequest", request, {
    headers: {'task_name': 'approverejectrequest'}});
}
const RegenerateSignupRequestOTP = (email: any) => {
  return http.post(`user/1/Users/RegenerateSignupRequestOTP?email=${email}`, {
    headers: {'task_name': 'regenerateotp'}});
}

const GetAdminUserList = () => {
  return http.get("user/1/Users/ListAdminUsers" , {
    headers: {'task_name': 'getadminuserlist'}});
}
const GetProviderUserList = () => {
  return http.get("user/1/Users/ListProviderUsers" , {
    headers: {'task_name': 'getprovideruserlist'}});
}
const updateAdmin = (data: any) => {
  return http.post("user/1/Users/ModifyAdminUser", data, {
    headers: {'task_name': 'updateadmin'}});
};

const updateProviderbyAdmin = (data: any) => {
  return http.post("user/1/Users/UpdateProviderProfile", data, {
    headers: {'task_name': 'updateProviderbyAdmin'}});
};

const updateProvider = (data: any) => {
  return http.post("user/1/Users/UpdateMyProfile", data, {
    headers: {'task_name': 'updateprovider'}});
};

const createAdmin = (data: any) => {
  return http.post("user/1/Users/VerifyOrCreateAdminUser", data, {
    headers: {'task_name': 'createadmin'}});
};

const GetRequestList = (data:any) => {
  return http.get(`user/1/Users/ListAllSignupRequests?userSignupRequestStatus=${data}`, {
    headers: {'task_name': 'getrequestlist'}});
}

const changePassword = (password:any) => {
  return http.post("user/1/Users/ChangePassword",password, {
    headers: {'task_name': 'changepassword'}});
}



export default {
  updateProviderbyAdmin,
  changePassword,
  ApproveRejectSignUpRequest,
  RegenerateSignupRequestOTP,
  GetRequestList,
  GetProviderUserList,
  createAdmin,
  updateAdmin,
  updateProvider,
  getProviderProfile,
  GetAdminUserList,
  ProviderSignUP,
};